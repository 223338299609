import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import calculateConvexHull from 'geo-convex-hull';
import {
  Typography, ListSubheader, TextField, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import parts from './parts';
// import sections from './sections';

const useStyles = makeStyles((theme) => ({
  root: {},
  merge: {
    position: 'absolute',
    top: 120,
    right: 0,
    height: '350px',
    width: '400px',
    backgroundColor: 'white',
    padding: 0,
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    marginLeft: '-15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  button: {
    position: 'absolute',
    right: 20,
    bottom: 20
  },
  error: {
    fontFamily: 'Roboto',
    color: 'red',
    paddingLeft: '15px',
    paddingTop: '15px'
  }
}));

const EditBox = (props) => {
  const classes = useStyles();
  const [ result, setResult ] = useState(null);
  const [ mergeable, setMergeable ] = useState(true);
  const [ parts, setParts ] = useState(null);
  const [ sections, setSections ] = useState(null);
  const [ part, setPart ] = useState("");
  const [ section, setSection ] = useState("");
  const [ error, setError ] = useState("");

  useEffect(() => {
    getAdjacentSections()
  },[props.mergeBox, props.part, props.section])

  const getAdjacentSections = async () => {
    if(props.mergeBox){
      let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
      let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
      let result = await axios.get(`/v1/constituency/nearby?state=${stateParam}&assembly_constituency=${constituencyParam}&part=${props.part}&section=${props.section}`);
      setResult(result);
      setParts(result.data.nearByParts);
      if(result.data.nearByParts.length){
        setPart(result.data.nearByParts[0].part)
        props.fromPart(result.data.nearByParts[0].part)
      } else {
        setMergeable(false);
        setPart({part: props.part})
        props.fromPart({part: props.part})
        setParts([{part: props.part}])
      }
      if(result.data.nearBySections.length){
        setSections(result.data.nearBySections);
        setSection(result.data.nearBySections[0].section);
      } else {
        setMergeable(false);
      }
    }
  }

  const getSection = () => {
    if(result){
      let filteredSections = result.data.nearBySections.filter(item => item.part === Number(part));
      setSections(filteredSections);
      if(filteredSections.length){
        setSection(filteredSections[0].section)
      } else {
        setSection("")
      }
    }
  }

  useEffect(() => {
    getSection()
  }, [part])

  useEffect(() => {
    props.fromSection(section)
  }, [section])

  const handleMergeClose = () => {
    props.handle(false);
    props.manage(false);
    setError("");
  }

  const submit = async () => {
    if(props.part === JSON.stringify(part) && props.section === JSON.stringify(section)){
      setError("Merge Section to Itself! Error!");
      return
    }
    let points = [];
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
    let primaryCoord = await axios.get(`/v1/constituency/section-coordinates?state=${stateParam}&assembly_constituency=${constituencyParam}&part=${props.part}&section=${props.section}`);
    for(let i = 0; i < primaryCoord.data.length; i++){
      let obj = {};
      obj.longitude = primaryCoord.data[i][0];
      obj.latitude = primaryCoord.data[i][1];
      points.push(obj);
    }
    let secondaryCoord = await axios.get(`/v1/constituency/section-coordinates?state=${stateParam}&assembly_constituency=${constituencyParam}&part=${part}&section=${section}`);
    for(let i = 0; i < secondaryCoord.data.length; i++){
      let obj = {};
      obj.longitude = secondaryCoord.data[i][0];
      obj.latitude = secondaryCoord.data[i][1];
      points.push(obj);
    }
    let convexHull = calculateConvexHull(points);
    convexHull.push(convexHull[0]);
    let mergedPolygon = [];
    for(let i = 0; i < convexHull.length; i++){
      let arr = [];
      arr.push(convexHull[i].longitude);
      arr.push(convexHull[i].latitude);
      mergedPolygon.push(arr);
    }
    let mergedCoord = { type: 'Polygon', coordinates: [mergedPolygon]}
    props.submitHandle(mergedCoord);
  }

  useEffect(() => {
    setError("")
  }, [props.mergeBox])

  return (
    <div 
    className={props.mergeBox ? classes.merge : classes.collapse}
    >
        <div 
            className={classes.close} 
            onClick={handleMergeClose}
        >
            <CloseIcon/>
            <Typography>
                Close
            </Typography>
        </div>
        <ListSubheader
          style={{paddingLeft: '5px'}}
        >
          Merge Section To
        </ListSubheader>
        <TextField
            fullWidth
            margin="dense"
            name="houseNo"
            value={`Part-${props.part}, Section-${props.section}`}
            disabled
            variant="outlined"
        />
        <TextField
            fullWidth
            margin="dense"
            name="Part"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            onChange={(e) => {
              setPart(e.target.value)
              props.fromPart(e.target.value)
              setError("")
            }}
            value={part}
        >
            <option
                disabled
                value=""
            />
            {parts && parts.length && parts.map((option) => (
                <option
                  key={option.part}
                  value={option.part}
                >
                  {option.part}
                </option>
            ))}
        </TextField>
        <TextField
            fullWidth
            margin="dense"
            name="Section"
            select
            SelectProps={{ native: true }}
            variant="outlined"
            onChange={(e) => {
              setSection(e.target.value)
              setError("")
            }}
            value={section}
        >
            <option
                disabled
                value=""
            />
            {sections && sections.map((option) => (
                <option
                key={option.section}
                value={option.section}
                >
                {option.section}
                </option>
            ))}
        </TextField>
        {error ? <div className={classes.error}>
          {error}
        </div> : null}
        <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={submit}
            disabled={!mergeable}
        >
            Merge
        </Button>
    </div>
  );
}

export default EditBox;
