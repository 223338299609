import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <RouterLink to="/">
          <div style={{display: 'flex', alignItems: 'center'}}>
            {/* <img
              alt="Logo"
              src="/images/logos/ECI-logo.png"
              style={{height: '40px', width: '40px', marginRight: '15px', marginLeft: '10px'}}
            /> */}
            <span style={{fontFamily: "sans-serif", color: "white"}}>DOMINOS DASHBOARD</span>
          </div>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
