const section   =  ["Section-01",
                    "Section-02",
                    "Section-03",
                    "Section-04",
                    "Section-05",
                    "Section-06",
                    "Section-07",
                    "Section-08",
                    "Section-09"]

export default section;