import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, TextField, Grid, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
var request = require('request');

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    position: 'absolute',
    left: 300,
    top: 200,
    width: '600px',
    backgroundColor: 'white',
    padding: 0,
    paddingBottom: '5px',
    transform: 'translateX(150)',
    transform: 'translateY(100)',
    zIndex: 10,
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  header: {
    width: '100%',
    height: '50px',
    display: 'flex',
    borderBottom: '1px solid #e4f0ef',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  warning: {
    padding: '10px 15px',
    borderBottom: '1px solid #e4f0ef',
    display: 'flex',
    justifyContent: 'center'
  },
  form_field: {
    display: 'flex',
    alignItems: 'center'
  },
  footer: {
    float: 'right',
    fontFamily: 'Roboto',
    paddingRight: '20px',
    paddingTop: '5px'
  },
  footer_right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const EditSectionBox = (props) => {
  const classes = useStyles();
  const [ editing, setEditing ] = useState(false);

  useEffect(() => {
    setEditing(false);
  },[props.editing])

  const handleEditClose = () => {
    props.handle(null);
  }

  const submitHandler = () => {
    setEditing(true);
    props.submit();
  }

  return (
    <div 
    className={props.editing ? classes.edit : classes.collapse}
    >
        {!editing ? <div>
            <div className={classes.header}>
                <Typography
                    style={{fontWeight: 'bold'}}
                >
                    Edit Section Boundary
                </Typography>
                <CloseIcon style={{fontSize: '17px', opacity: '0.3'}} onClick={handleEditClose}/>
            </div>
            <div className={classes.warning}>
                <Typography
                    style={{fontWeight: 'bold'}}
                >
                    Are you sure, you want to modify the section?
                </Typography>
            </div>
            <div className={classes.footer}>
                <div className={classes.footer_right}>
                    <Typography
                        style={{fontSize: '14px', fontWeight: 'bold', marginRight: '20px'}}
                        onClick={handleEditClose}
                    >
                        CLOSE
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={submitHandler}
                    >
                        SAVE CHANGES
                    </Button>
                </div>
            </div>
        </div> :
        <div className={classes.loader}>
            <img 
                src="/images/loading.gif"
                alt="Loading"
            />
        </div> }
    </div>
  );
}

export default EditSectionBox;
