import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid
} from '@material-ui/core';
import Buildings from './Buildings';
import Epics from './Epics';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    },
    '&:hover': {
      backgroundColor: 'blue'
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

function Overview({ className, ...rest }) {
  const classes = useStyles();

  return (
      <Grid
        container
        spacing={3}
        className={classes.grid}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <Buildings buildings={rest.buildings} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <Epics epics={rest.epics} />
        </Grid>
      </Grid>
  );
}

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
