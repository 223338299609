import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, ListSubheader, TextField, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import parts from './parts';
import sections from './sections';

const useStyles = makeStyles((theme) => ({
  root: {},
  merge: {
    position: 'absolute',
    top: '50%',
    right: 0,
    height: '350px',
    width: '300px',
    backgroundColor: 'white',
    padding: 0,
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px 5px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    marginLeft: '-15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  button: {
    float: 'right',
    transform: 'translateY(30px)'
  }
}));

const DwellingsMergeBox = (props) => {
  const classes = useStyles();

  const handleMergeClose = () => {
    props.handle('');
  }

  return (
    <div 
    className={classes.merge}
    >
        <div 
            className={classes.close} 
            onClick={handleMergeClose}
        >
            <CloseIcon/>
            <Typography>
                Close
            </Typography>
        </div>
        <Typography
            component="h2"
            gutterBottom
            variant="h3"
            style={{textTransform: 'none', marginLeft: '9px'}}
        >
            {props.dwelling}
        </Typography>
        <ListSubheader
          style={{paddingLeft: '5px'}}
        >
          Merge Dwellings To
        </ListSubheader>
        <TextField
            fullWidth
            margin="dense"
            name="houseNo"
            value="Part-01, Section-09"
            disabled
            variant="outlined"
        />
        <TextField
            fullWidth
            margin="dense"
            name="Part"
            select
            SelectProps={{ native: true }}
            variant="outlined"
        >
            <option
                disabled
                value=""
            />
            {parts.map((option) => (
                <option
                key={option}
                value={option}
                >
                {option}
                </option>
            ))}
        </TextField>
        <TextField
            fullWidth
            margin="dense"
            name="Section"
            select
            SelectProps={{ native: true }}
            variant="outlined"
        >
            <option
                disabled
                value=""
            />
            {sections.map((option) => (
                <option
                key={option}
                value={option}
                >
                {option}
                </option>
            ))}
        </TextField>
        <Button
            className={classes.button}
            color="primary"
            variant="contained"
        >
            Merge
        </Button>
    </div>
  );
}

export default DwellingsMergeBox;
