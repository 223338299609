import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import {
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    position: 'absolute',
    right: 0,
    top: 200,
    height: '60vh',
    width: '410px',
    backgroundColor: 'white',
    padding: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)',
    zIndex: 1
  },
  collapseInfo: {
    display: 'none'
  },
  closeInfo: {
    zIndex: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  address: {
    display: 'flex',
    backgroundColor: '#5848e8',
    height: '10vh',
    padding: '8px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    alignItems: 'center'
  },
  ddn: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '25px',
    color: '#fff'
  },
  dwellings: {
    width: '100%',
    marginTop: '4px',
    boxShadow: '1px 1px 3px'
  },
  dwellings_header: {
    marginLeft: '8px',
    marginBottom: '6px',
    paddingTop: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  floors: {
    width: 'cover',
    height: '40px',
    // boxShadow: '1px 1px 3px',
    border: '1px solid rgba(0, 0, 0, .3)',
    paddingTop: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  floorDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  parentEpic: {
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)'
    },
    fontWeight: 'bold',
    transition: 'all 0.3s',
    backfaceVisibility: 'hidden'
  },
  familyEpics: {
    backgroundColor: '#c4cccc',
    listStyle: 'none'
  },
  familyEpic: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    border: '1px solid #708c89',
    height: '35px',
    paddingLeft: '20px',
    paddingTop: '10px'
  },
  familyEpicDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '12px'
  },
  groupAddButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: '5px',
    marginBottom: '-3px'
  },
  deleteButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    fontSize: '19px'
  },
  addButtons: {
    display: 'flex'
  },
  addButton: {
    marginRight: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addButtonText: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'bold'
  }
}));

const DDNPanel = (props) => {
  const classes = useStyles();
  const [ showFamilyEpics, setShowFamilyEpics ] = useState("");
  const [ buildingDetails, setBuildingDetails ] = useState(null);

  const handleInfoClose = () => {
    props.handle("");
  }

  const familyEpicToggle = (value) => {
    if(!showFamilyEpics || value !== showFamilyEpics){
      setShowFamilyEpics(value)
    } else {
      setShowFamilyEpics("");
    }
  }

  useEffect(() => {
    getBuildingDetails()
  },[props.ddnPanel, props.refreshList])

  const getBuildingDetails = async () => {
    setBuildingDetails(null);
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let result = await axios.get(`https://dev.zip.pr/eci/building?building_id=${props.ddnPanel}`, options);
    if(result.data.ok){
      setBuildingDetails(result.data.response.record);
      props.epicBuildingId(props.ddnPanel);
      props.epicState(result.data.response.record.state);
      props.epicAssembly(result.data.response.record.assembly_constituency);
      props.refreshHandle(false);
    }
  }

  return (
    <div 
    className={props.ddnPanel ? classes.info : classes.collapseInfo}
    >
      <div style={{position: 'relative'}}>
        <div 
          className={classes.closeInfo}
          onClick={handleInfoClose}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <CloseIcon/>
            <Typography>
              Close
            </Typography>
          </div>
        </div>
        <div className={classes.address}>
          <div className={classes.ddn}>{props.ddnPanel}</div>
        </div>
        <div className={classes.dwellings}>
          <div className={classes.dwellings_header}>
            <div>
              <Typography
                style={{fontSize: '10px'}}
              >
                Families
              </Typography>
            </div>
            <div className={classes.addButtons}>
              <div className={classes.addButton} onClick={() => props.epicAdd("Parent")}>
                <PersonAddIcon style={{marginBottom: '-10px'}}/>
                <p className={classes.addButtonText}>Parent</p>
              </div>
            </div>
          </div>
          { buildingDetails ? <ul style={{listStyle: 'none', height: '40vh', overflowY: 'scroll'}}>
            { buildingDetails.epic_objs.map(item => {
              return (
                <>
                  <li key={item.parent_epic_obj.epic_no} className={classes.floors}>
                    <div className={classes.floorDiv}>
                      <div onClick={() => familyEpicToggle(item.parent_epic_obj.epic_no)} className={classes.parentEpic}>{item.parent_epic_obj.epic_no}({item.parent_epic_obj.family_epic_objs.length})</div>
                      <div>
                        <GroupAddIcon className={classes.groupAddButton} onClick={() => {
                          props.epicParentId(item.parent_epic_obj.epic_no)
                          props.epicAdd("Family")}
                        }/>
                        <DeleteIcon className={classes.deleteButton} onClick={() => {
                          props.epicParentId(item.parent_epic_obj.epic_no)
                          props.epicDelete(item.parent_epic_obj.epic_no)
                          props.deleting("Parent")} 
                        }/>
                      </div>
                    </div>
                  </li>
                  { showFamilyEpics === item.parent_epic_obj.epic_no ? <ul className={classes.familyEpics}>
                    {item.parent_epic_obj.family_epic_objs.map(family => {
                      return (
                        <li key={family.epic_no} className={classes.familyEpic}>
                          <div className={classes.familyEpicDiv}>
                            <div>
                              {family.epic_no}
                            </div>
                            <div>
                              <DeleteIcon className={classes.deleteButton} onClick={() => {
                                props.epicParentId(item.parent_epic_obj.epic_no)
                                props.epicDelete(family.epic_no)
                                props.deleting("Family")}
                              }/>
                            </div>
                          </div>                 
                        </li>
                      )
                    })}
                  </ul> : null }
                </>
              )
            }) }
          </ul> : null }
        </div>
      </div>
    </div>
  );
}

export default DDNPanel;
