import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, ListSubheader
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoBox from './infoBox';

const useStyles = makeStyles((theme) => ({
  root: {},
  filter: {
    position: 'absolute',
    right: 0,
    top: 80,
    height: '600px',
    width: '300px',
    backgroundColor: 'white',
    padding: 0,
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapseFilter: {
    display: 'none'
  },
  closeFilter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filterSection: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  subHeader: {
    marginLeft: '8px',
    paddingLeft: 0
  },
  infoSection: {
    backgroundColor: '#e9f2ed',
    height: '60%',
    width: '100%',
    border: '1px solid rgba(0,0,0,0.2)'
  },
  infoText: {
    margin: '8px',
    fontFamily: 'Roboto',
    fontSize: '14px'
  }
}));

const InfoPanel = (props) => {
  const classes = useStyles();
  const [level, setLevel] = useState("");

  useEffect(() => {
    setLevel(props.infoType)
  },[props.infoType])

  const handleFilterClose = () => {
    props.handle(false);
  }

  return (
    <div 
    className={props.infoPanel ? classes.filter : classes.collapseFilter}
    >
        <div 
            className={classes.closeFilter}
            onClick={handleFilterClose}
        >
            <CloseIcon/>
            <Typography>
            Close
            </Typography>
        </div>
        <ListSubheader
          className={classes.subHeader}
        >
          {level} with Epic No's
        </ListSubheader>
        <div className={classes.infoSection}>
          <InfoBox state={props.state} constituency={props.constituency} part={props.part} section={props.section} infoType={props.infoType}/>
        </div>
    </div>
  );
}

export default InfoPanel;
