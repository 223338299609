import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import request from 'request';
import {
  Typography, TextField, Grid, Button, ListSubheader
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    position: 'absolute',
    left: 300,
    top: 0,
    width: '600px',
    backgroundColor: 'white',
    paddingBottom: 10,
    transform: 'translateX(150)',
    transform: 'translateY(100)',
    zIndex: 10,
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  header: {
    width: '100%',
    height: '50px',
    display: 'flex',
    borderBottom: '1px solid #e4f0ef',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  form: {
    padding: '15px',
    borderBottom: '1px solid #e4f0ef',
  },
  footer: {
    float: 'right',
    fontFamily: 'Roboto',
    paddingRight: '20px',
    paddingTop: '5px'
  },
  footer_right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer'
    }
  }
}));

const AddBuildingModal = (props) => {
  const classes = useStyles();
  const [part, setPart] = useState("");
  const [error, setError] = useState("");

  const handleEditClose = () => {
    props.handle(null);
    setPart("");
  }

  const createBuildingHandler = async () => {
    // console.log(props.state, props.constituency, part, props.building);
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
    var options = {
        'method': 'POST',
        'url': 'https://dev.zip.pr/eci/building',
        'headers': {
          'Content-Type': 'application/json',
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        },
        body: JSON.stringify({
            "latitude" : props.building[1],
            "longitude" : props.building[0],
            "state": stateParam,
            "assembly_constituency" : constituencyParam,
            "part": Number(part),
            "ero_id": localStorage.getItem('user')
        })
      };
      request(options, function (error, response) { 
        if(JSON.parse(response.body).ok){
            props.showPanel(JSON.parse(response.body).response.building_id);
            props.handle(null);
            setPart("");
        }
      });
  }

  return (
    <div 
    className={props.building ? classes.edit : classes.collapse}
    >
        <div className={classes.header}>
            <Typography
                style={{fontWeight: 'bold'}}
            >
                Add Building
            </Typography>
            <CloseIcon style={{fontSize: '17px', opacity: '0.3'}} onClick={handleEditClose}/>
        </div>
        <div className={classes.form}>
            <Grid
                container
            >
                <Grid
                    item
                    xs={4}
                >
                    <ListSubheader
                    style={{color: 'rgba(0,0,0)'}}
                    >
                        State
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <Typography style={{paddingTop: '13px'}}>
                        {props.state}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <ListSubheader
                    style={{color: 'rgba(0,0,0)'}}
                    >
                        Constituency
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <Typography style={{paddingTop: '13px'}}>
                        {props.constituency}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <ListSubheader
                    style={{color: 'rgba(0,0,0)'}}
                    >
                        Part
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        onChange={(e) => setPart(e.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <ListSubheader
                    style={{color: 'rgba(0,0,0)'}}
                    >
                        Building Coordinates
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    {props.building ? <Typography style={{paddingTop: '13px'}}>
                        {props.building[0]}, {props.building[1]}
                    </Typography> : null}
                </Grid>
            </Grid>
        </div>
        <div className={classes.footer}>
            <div className={classes.footer_right}>
                <Typography
                    style={{fontSize: '14px', fontWeight: 'bold', marginRight: '20px'}}
                    onClick={handleEditClose}
                >
                    CLOSE
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={createBuildingHandler}
                >
                    CREATE BUILDING
                </Button>
            </div>
        </div>
    </div>
  );
}

export default AddBuildingModal;
