import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import {
    Button,
    ListSubheader,
    TextField,
    Grid
  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  epic: {
    position: 'absolute',
    top: 5,
    left: 20,
    width: '79vw',
    height: '710px',
    zIndex: 9
  },
  collapse: {
    display: 'none'
  },
  box_1: {
    backgroundColor: 'rgba(255,255,255)',
    marginBottom: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: 2,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  box_2: {
    backgroundColor: 'rgba(255,255,255)',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    borderRadius: 2,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  submitButton: {
    textAlign: 'right',
    marginTop: '20px'
  }
}));

const EpicInfoDetails = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState(null);
  const [epicNotFoundError, setEpicNotFoundError] = useState(false);

  const getParentEpicDetails = async () => {
    setDetails(null);
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let result = await axios.get(`https://dev.zip.pr/eci/epic?epic_no=${props.parentId}`, options);
    if(result.data.ok){
        setDetails(result.data.response.epic_obj);
    } else {
        setEpicNotFoundError(true);
    }
  }

  const getFamilyEpicDetails = async () => {
    setDetails(null);
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let result = await axios.get(`https://dev.zip.pr/eci/epic?epic_no=${props.familyId}`, options);
    if(result.data.ok){
        setDetails(result.data.response.epic_obj);
    } else {
        setEpicNotFoundError(true);
    }
  }

  useEffect(() => {
    if(props.familyId){
        getFamilyEpicDetails()
    } else {
        getParentEpicDetails()
    }
  },[props.show])

  return (
    <div 
        className={props.show ? classes.epic : classes.collapse}
    >
        <CloseIcon 
            style={{fontSize: '14px',position: 'absolute', top: 2, right: 2}}
            onClick={()=> props.handle(false)}
        /> 
        {details ? <div className={classes.box_2}>
            <Grid
                container
            >
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', fontSize: '20px', fontWeight: 'bold', color: 'rgba(0,0,0)'}}
                    >
                        Epic Info
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >           
                </Grid>
                {props.show === 'Family' ? <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Parent Epic Number
                    </ListSubheader>
                </Grid> : null }
                {props.show === 'Family' ? <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.parentId}
                    </ListSubheader>
                </Grid> : null }
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.show} Epic Number
                    </ListSubheader>
                </Grid>
                {props.show === 'Family' ? <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.familyId}
                    </ListSubheader>
                </Grid> : 
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.parentId}
                    </ListSubheader>
                </Grid> }
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Building Id
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.building}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        State
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.state}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Assembly Constituency
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {props.assembly}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Address
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.ADDRESS}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Name
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.FM_NAME_EN}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Age
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.AGE}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Gender
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.GENDER}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Father/Husband Name
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.RLN_FM_NM_EN}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Part Number
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.PART_NO}
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        Section Number
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px'}}
                    >
                        {details.SECTION_NO}
                    </ListSubheader>
                </Grid>
            </Grid>
        </div>  : null }     
    </div>
  );
}

export default EpicInfoDetails;
