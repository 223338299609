import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import {
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import DirectionsIcon from '@material-ui/icons/Directions';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import DeleteIcon from '@material-ui/icons/Delete';
import DwellingsMergeBox from './dwellingsMergeBox';

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    position: 'absolute',
    right: 0,
    top: 70,
    height: '650px',
    width: '410px',
    backgroundColor: 'white',
    padding: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapseInfo: {
    display: 'none'
  },
  imgDiv: {
    width: '100%',
  },
  ddnImage: {
    width: '100%',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2
  },
  closeInfo: {
    zIndex: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  address: {
    backgroundColor: '#5848e8',
    height: '100px',
    padding: '8px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2
  },
  ddn: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#fff'
  },
  addressTextDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '12px',
    position: 'relative'
  },
  direction: {
    position: 'absolute',
    right: 10,
    top: -47,
    backgroundColor: '#fff',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    color: '#5848e8',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filterSection: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  subHeader: {
    marginLeft: '8px',
    paddingLeft: 0
  },
  infoSection: {
    backgroundColor: '#e9f2ed',
    height: '60%',
    width: '100%'
  },
  infoText: {
    margin: '8px',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  dwellings: {
    width: '100%',
    marginTop: '4px',
    boxShadow: '1px 1px 3px'
  },
  dwellings_header: {
    marginLeft: '8px',
    marginBottom: '6px',
    paddingTop: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  floors: {
    width: 'cover',
    height: '40px',
    // boxShadow: '1px 1px 3px',
    border: '1px solid rgba(0, 0, 0, .3)',
    paddingTop: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  floorDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  parentEpic: {
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)'
    },
    fontWeight: 'bold',
    transition: 'all 0.3s',
    backfaceVisibility: 'hidden'
  },
  familyEpics: {
    backgroundColor: '#c4cccc',
    listStyle: 'none'
  },
  familyEpic: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    border: '1px solid #708c89',
    height: '35px',
    paddingLeft: '20px',
    paddingTop: '10px'
  },
  familyEpicDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '12px'
  },
  mergeBox: {
    position: 'absolute',
    right: '100%',
    top: '100px'
  },
  addressEditButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  groupAddButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: '5px',
    marginBottom: '-3px'
  },
  mergeButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: '5px',
    fontSize: '19px'
  },
  editButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: '5px',
    fontSize: '19px'
  },
  deleteButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    fontSize: '19px'
  },
  addButtons: {
    display: 'flex'
  },
  addButton: {
    marginRight: '20px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addButtonText: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'bold'
  }
}));

const DDNPanel = (props) => {
  const classes = useStyles();
  const [ dwelling, setDwelling ] = useState('');
  const [ showFamilyEpics, setShowFamilyEpics ] = useState("");
  const [ buildingDetails, setBuildingDetails ] = useState(null);

  const handleInfoClose = () => {
    props.handle("");
    setDwelling('');
  }

  const handleEdit = () => {
    if(buildingDetails){
      props.edit(buildingDetails.address)
    }
  }

  const familyEpicToggle = (value) => {
    if(!showFamilyEpics || value !== showFamilyEpics){
      setShowFamilyEpics(value)
    } else {
      setShowFamilyEpics("");
    }
  }

  useEffect(() => {
    getBuildingDetails()
    props.setRefresh(false);
  },[props.ddnPanel, props.refresh])

  const getBuildingDetails = async () => {
    setBuildingDetails(null);
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let result = await axios.get(`https://dev.zip.pr/eci/building?building_id=${props.ddnPanel}`, options);
    if(result.data.ok){
      setBuildingDetails(result.data.response.record);
      props.epicBuildingId(props.ddnPanel);
      props.epicState(result.data.response.record.state);
      props.epicAssembly(result.data.response.record.assembly_constituency)
    }
  }

  return (
    <div 
    className={props.ddnPanel ? classes.info : classes.collapseInfo}
    >
      <div style={{position: 'relative'}}>
        <div 
          className={classes.closeInfo}
          onClick={handleInfoClose}
        >
          <div style={{display: 'flex', alignItems: 'center', color: '#000'}}>
            <CloseIcon/>
            <Typography style={{color: '#000'}}>
              Close
            </Typography>
          </div>
        </div>
        <div className={classes.imgDiv}>
          {buildingDetails && buildingDetails.image ? 
            <img 
              src={buildingDetails.image}
              className={classes.ddnImage}
              alt="DDN 1"
            /> :
            <img 
              src="/images/no-image-box.png"
              className={classes.ddnImage}
              alt="DDN 1"
            /> }
          <div className={classes.address}>
            <div className={classes.ddn}>{props.ddnPanel}</div>
            <div
              className={classes.addressTextDiv}
            >
              <div>
                <h3>Address</h3>
                {buildingDetails ? <p>{buildingDetails.address}</p> : null}
              </div>
              <div className={classes.addressEditButton}>
                <EditIcon onClick={handleEdit}/>
              </div>
              <button className={classes.direction}>
                <DirectionsIcon/>
              </button>
            </div>
          </div>
          <div className={classes.dwellings}>
            <div className={classes.dwellings_header}>
              <div>
                <Typography
                  style={{fontSize: '10px'}}
                >
                  Families
                </Typography>
              </div>
            </div>
            { buildingDetails ? <ul style={{listStyle: 'none'}}>
              { buildingDetails.epic_objs.map(item => {
                return (
                  <>
                    <li key={item.parent_epic_obj.epic_no} className={classes.floors}>
                      <div className={classes.floorDiv}>
                        <div onClick={() => familyEpicToggle(item.parent_epic_obj.epic_no)} className={classes.parentEpic}>{item.parent_epic_obj.epic_no}({item.parent_epic_obj.family_epic_objs.length})</div>
                        <div>
                          {/* <CallMergeIcon className={classes.mergeButton} onClick={() => setDwelling(item.parent_epic_obj.epic_no)}/> */}
                          <InfoIcon className={classes.editButton} onClick={() => {
                            props.epicParentId(item.parent_epic_obj.epic_no)
                            props.epicEdit("Parent")}
                          }/>
                          {/* <DeleteIcon className={classes.deleteButton} onClick={() => props.epicDelete(item.parent_epic_obj.epic_no)}/> */}
                        </div>
                      </div>
                    </li>
                    { showFamilyEpics === item.parent_epic_obj.epic_no ? <ul className={classes.familyEpics}>
                      {item.parent_epic_obj.family_epic_objs.map(family => {
                        return (
                          <li key={family.epic_no} className={classes.familyEpic}>
                            <div className={classes.familyEpicDiv}>
                              <div>
                                {family.epic_no}
                              </div>
                              <div>
                                <InfoIcon className={classes.editButton} onClick={() => {
                                  props.epicFamilyId(family.epic_no)
                                  props.epicEdit("Family")}
                                }/>
                                {/* <DeleteIcon className={classes.deleteButton} onClick={() => props.epicDelete(family.epic_no)}/> */}
                              </div>
                            </div>                 
                          </li>
                        )
                      })}
                    </ul> : null }
                  </>
                )
              }) }
            </ul> : null }
          </div>
        </div>
        {dwelling.length ?
          <div className={classes.mergeBox}>
            <DwellingsMergeBox dwelling={dwelling} handle={setDwelling}/>
          </div> : null }
      </div>
    </div>
  );
}

export default DDNPanel;
