/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Avatar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Link,
  Typography
} from '@material-ui/core';
import NavbarMoreButton from '../../components/NavbarMoreButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  details: {
    marginLeft: theme.spacing(2)
  },
}));

function TopBar({
  onOpenNavBarMobile,
  className,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleLogout = async () => {
    let options = {
      username: localStorage.getItem('user')
    };
    await axios.post('/user/v1/logout', options);
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('user_type');
    localStorage.removeItem('clientId');
    history.push('/auth/login');
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <div style={{display: 'flex', alignItems: 'center'}}>
            {/* <img
              alt="Logo"
              src="/images/logos/ECI-logo.png"
              style={{height: '40px', width: '40px', marginRight: '15px', marginLeft: '10px'}}
            /> */}
            <span style={{fontFamily: "sans-serif", color: "white"}}>TRADEZONE ANALYTICS</span>
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        
        <Hidden mdDown>
          {/* <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar
              alt="Person"
              className={classes.avatar}
              // src='../../images/avatars/avatar_11.png'
            />
          </Badge> */}
          <div className={classes.details}>
            <Typography variant="h5" style={{color: 'white', fontWeight: 'bold'}}>{localStorage.getItem('name')}</Typography>
            {/* <Typography variant="body2">{session.user.bio}</Typography> */}
            <Typography variant="h4" style={{color: 'white', fontWeight: 'bold'}}>{localStorage.getItem('user')}</Typography>
          </div>
          <NavbarMoreButton logout={handleLogout} style={{color: 'white'}}/>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
