import React, {
    useRef,
    useState,
    memo
  } from 'react';
  import PropTypes from 'prop-types';
  import {
    ListItemIcon,
    ListItemText,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
  } from '@material-ui/core';
  import MoreIcon from '@material-ui/icons/MoreVert';
  import InputIcon from '@material-ui/icons/Input';
  
  function NavbarMoreButton(props) {
    const moreRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
  
    const handleMenuOpen = () => {
      setOpenMenu(true);
    };
  
    const handleMenuClose = () => {
      setOpenMenu(false);
    };
  
    return (
      <>
        <Tooltip title="More options">
          <IconButton
            {...props}
            onClick={handleMenuOpen}
            ref={moreRef}
            size="small"
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={moreRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          elevation={1}
          onClose={handleMenuClose}
          open={openMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={props.logout}>
            <ListItemIcon>
              <InputIcon/>
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </MenuItem>
        </Menu>
      </>
    );
  }
  
  NavbarMoreButton.propTypes = {
    className: PropTypes.string
  };
  
  export default memo(NavbarMoreButton);
  