import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Input,
  ListSubheader,
  TextField,
  Typography
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import InfoPanel from './infoPanel';
import DDNPanel from './DDNPanel';
import EditBox from './editBox';
import EditSectionBox from './editSectionBox';
import MergeBox from './mergeBox';
import SectionPopup from './sectionPopup';
import Backdrop from './backdrop';
import EpicInfoDetails from './epicInfoDetails';
import EpicDelete from './epicDelete';
import MergeSuccess from './mergeSuccess';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';

const useStyles = makeStyles((theme) => ({
  root: {},
  searchBar: {
    position: 'absolute', 
    top: 0, 
    left: 0, 
    zIndex: 2, 
    backgroundColor: 'rgba(255,255,255,0.7)', 
    width: '100%', 
    height: '50px', 
    border: '1px solid transparent',
    paddingRight: '5%'
  },
  searchBarLeft: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: '20px'
  },
  searchBarRight: {
    height: '100%',
    width: '25%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  search: {
    backgroundColor: 'rgba(0,0,0, 0.4)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  filterButton: {
    backgroundColor: 'white',
    borderRadius: '10%',
    width: '30px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filter: {
    position: 'absolute',
    right: 0,
    top: 100,
    height: '400px',
    width: '400px',
    backgroundColor: 'white',
    padding: '8px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapseFilter: {
    display: 'none'
  },
  closeFilter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filterSection: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  selectGroup: {
    padding: theme.spacing(2, 0)
  },
  stateSelect: {
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    position: 'absolute',
    right: 20,
    bottom: 20
  },
  error: {
    fontFamily: 'Roboto',
    color: 'rgb(230, 39, 39)' 
  },
  optionStyle: {
    fontFamily: 'Roboto',
    padding: '10px',
    paddingTop: '0px'
  }
}));

const Map = () => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [draw, setDraw] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [selectEdge, setSelectEdge] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [filterOpen, setFilterOpen] = useState(true);
  const [searched, setSearched] = useState(false);
  const [infoPanel, setInfoPanel] = useState(false);
  const [infoType, setInfoType] = useState(null)
  const [buildings, setBuildings] = useState([]);
  const [ddnPanel, setDdnPanel] = useState("");
  const [buildingAddress, setBuildingAddress] = useState("");
  const [epicAddBox, setEpicAddBox] = useState("");
  const [epicEditBox, setEpicEditBox] = useState("");
  const [epicDelete, setEpicDelete] = useState("");
  const [refreshInfo, setRefreshInfo] = useState(false);
  const [mergeBox, setMergeBox] = useState(false);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [sectionPopup, setSectionPopup] = useState(false);
  const [popUpTop, setPopUpTop] = useState(0);
  const [popUpLeft, setPopUpLeft] = useState(0);
  const [manage, setManage] = useState(false);
  const [states, setStates] = useState(null);
  const [state, setState] = useState("");
  const [constituencies, setConstituencies] = useState(null);
  const [constituency, setConstituency] = useState("")
  const [part, setPart] = useState("");
  const [section, setSection] = useState("");
  const [constituencyResult, setConstituencyResult] = useState(null);
  const [partResult, setPartResult] = useState(null);
  const [sectionResult, setSectionResult] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [newCoord, setNewCoord] = useState(null);
  const [toSection, setToSection] = useState('');
  const [fromPart, setFromPart] = useState('');
  const [fromSection, setFromSection] = useState('');
  const [mergedPolygonCoord, setMergedPolygonCoord] = useState(null);
  const [error, setError] = useState(false);
  const [epicBuildingId, setEpicBuildingId] = useState("");
  const [epicState, setEpicState] = useState("");
  const [epicAssembly, setEpicAssembly] = useState("");
  const [epicParentId, setEpicParentId] = useState("");
  const [epicFamilyId, setEpicFamilyId] = useState("");
  const [mergeSuccess, setMergeSuccess] = useState(false);
  const [searchEpic, setSearchEpic] = useState("");
  const [epicNotFound, setEpicNotFound] = useState("");
  const [i, setI] = useState(null);
  const [k, setK] = useState(null);
  const open = Boolean(selectEdge);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v9',
      center: [80, 20],
      zoom: 4.5
    });
    let nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'bottom-left');
    var draw = new MapboxDraw({
      displayControlsDefault: false,
      // controls: {
      //   polygon: true,
      //   trash: true
      // }
    })
    map.addControl(draw, 'bottom-right');
    setDraw(draw);
    map.on('move', async () => {
      const { lng, lat } = map.getCenter();
      setZoom(map.getZoom().toFixed(2));
      if(map.getZoom().toFixed(2) >= 18){
        let options = {
          'headers': {
            'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
          }
        };
        let response = await axios.get(`https://dev.zip.pr/eci/nearby?latitude=${lat}&longitude=${lng}`, options);
        if(response.data.ok){
          setBuildings(buildings => [
            ...buildings,
            ...response.data.response.buildings
          ]);
          for(let i = 0; i < response.data.response.buildings.length; i++){
            let mapSource = map.getSource(response.data.response.buildings[i].building_id);
            if(!mapSource){
              map.addSource(response.data.response.buildings[i].building_id, {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': response.data.response.buildings[i].centroid.coordinates
                  }
                }
              });
            }
            let polygonSource = map.getSource(`${response.data.response.buildings[i].building_id}-polygon`);
            if(!polygonSource){
              if(response.data.response.buildings[i].geometry){
                map.addSource(`${response.data.response.buildings[i].building_id}-polygon`, {
                  'type': 'geojson',
                  'data': {
                    'type': 'Feature',
                    'geometry': {
                      'type': 'Polygon',
                      'coordinates': response.data.response.buildings[i].geometry.coordinates
                    }
                  }
                });
              }
            }
            let mapLayer = map.getLayer(response.data.response.buildings[i].building_id);
            if(!mapLayer){
              map.addLayer({
                'id': response.data.response.buildings[i].building_id,
                'type': 'circle',
                "paint": {
                  "circle-radius": 6,
                  "circle-color": response.data.response.buildings[i].colour_code
                },
                "source": response.data.response.buildings[i].building_id
              });
            }
            let polygonLayer = map.getLayer(`${response.data.response.buildings[i].building_id}-polygon`);
            if(!polygonLayer){
              map.addLayer({
                'id': `${response.data.response.buildings[i].building_id}-polygon`,
                'type': 'line',
                'source': `${response.data.response.buildings[i].building_id}-polygon`,
                'layout': {},
                'paint': {
                  'line-color': '#f5fa93',
                  'line-width': 2
                }
              });
            }
            let mapLabel = map.getLayer(`${response.data.response.buildings[i].building_id}-label`);
            if(!mapLabel){
              map.addLayer({
                'id': `${response.data.response.buildings[i].building_id}-label`,
                'type': 'symbol',
                'source': response.data.response.buildings[i].building_id,
                'layout': {
                  'text-field': `${response.data.response.buildings[i].building_id}(${response.data.response.buildings[i].epics_count})`,
                  'text-size': 14,
                  'text-variable-anchor': ["left"],
                  'text-radial-offset': 0.3,
                  'text-justify': 'auto',
                },
                'paint': {
                  'text-color': '#fff308'
                }
              });
            }
            map.on('click', response.data.response.buildings[i].building_id, function(e){
              setInfoPanel(false);
              setDdnPanel(response.data.response.buildings[i].building_id);
              setManage(false);
              setMergeBox(false);
            });
            map.on('mousemove', response.data.response.buildings[i].building_id, function(e){
              map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', response.data.response.buildings[i].building_id, function(e){
              map.getCanvas().style.cursor = '';
            });
          }
        }
      }
    });
    setMap(map);
  },[])

  useEffect(() => {
    if(map){
      if(map.getZoom().toFixed(2) < 18){
        for(let i = 0; i < buildings.length; i++){
          let mapLayer = map.getLayer(buildings[i].building_id);
          if(mapLayer){
            map.removeLayer(buildings[i].building_id);
          }
          let mapLabel = map.getLayer(`${buildings[i].building_id}-label`);
          if(mapLabel){
            map.removeLayer(`${buildings[i].building_id}-label`)
          }
          let mapSource = map.getSource(buildings[i].building_id);
          if(mapSource){
            map.removeSource(buildings[i].building_id);
          }
          let polygonLayer = map.getLayer(`${buildings[i].building_id}-polygon`);
          if(polygonLayer){
            map.removeLayer(`${buildings[i].building_id}-polygon`);
          }
          let polygonSource = map.getSource(`${buildings[i].building_id}-polygon`);
          if(polygonSource){
            map.removeSource(`${buildings[i].building_id}-polygon`);
          }
        }
      }
    }
  },[buildings.length, zoom])

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let states = assigned_constituencies.map(item => item.state_display_name);
    setStates(states);
  },[])

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let state_constituencies = assigned_constituencies.filter(item => item.state_display_name === state);
    let constituencies = state_constituencies.map(item => item.ac_display_name);
    setConstituencies(constituencies);
  },[state])

  const setLocation = async () => {
    if(searched){
      window.location.reload()
    }
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let result = await axios.get(`/v1/map/constituency?state=${stateParam}&constituency=${constituencyParam}&start_date=${startDate.format('DD-MM-YYYY')}&end_date=${endDate.format('DD-MM-YYYY')}`);
    if(!result.data.ok){
      setError(true);
      return
    }
    setSearched(true);
    setFilterOpen(false);
    setInfoPanel(true);
    setInfoType('Constituency');
    setConstituencyResult(result);
    map.flyTo({center: result.data.response.center, zoom: 11.7})
    map.addSource(result.data.response.constituency, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': result.data.response.coordinates
        }
      }
    });
    map.addLayer({
      'id': result.data.response.constituency,
      'type': 'fill',
      'source': result.data.response.constituency,
      'layout': {},
      'paint': {
      'fill-color': '#fff',
      'fill-opacity': 0.3,
      'fill-outline-color': 'yellow'
      }
    });
    map.addLayer({
      'id': 'labels',
      'type': 'symbol',
      'source': result.data.response.constituency,
      'layout': {
        'text-field': result.data.response.constituency
      },
      'paint': {
        'text-color': '#fff308'
      }
    })
    map.addLayer({
      'id': 'first-layer-borders',
      'type': 'line',
      'source': result.data.response.constituency,
      'layout': {},
      'paint': {
        'line-color': '#fff308',
        'line-width': 3
      }
    });
    map.on('mouseenter', result.data.response.constituency, function() {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', result.data.response.constituency, function() {
      map.getCanvas().style.cursor = '';
    });
    map.on('click',result.data.response.constituency, async function(e){
      handleConstituencyClick(result);
    });
    setMap(map);
  }

  const handleConstituencyClick = async (result) => {
    map.flyTo({center: result.data.response.center, zoom: 12});
    map.removeLayer('first-layer-borders');
    map.removeLayer(result.data.response.constituency);
    map.removeLayer('labels');
    setInfoPanel(true);
    setInfoType('Part');
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let partResult = await axios.get(`/v1/map/constituency/parts?state=${stateParam}&constituency=${constituencyParam}&start_date=2020-02-26&end_date=2020-03-30`);
    setPartResult(partResult);
    for(let i = 0; i < partResult.data.response.length; i++){
      map.addSource(partResult.data.response[i].name, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [ partResult.data.response[i].coordinates ]
          }
        }
      });
      map.addLayer({
        'id': partResult.data.response[i].name,
        'type': 'fill',
        'source': partResult.data.response[i].name,
        'paint': {
          'fill-color': partResult.data.response[i].color,
          'fill-opacity': 0.3
        }
      });
      map.addLayer({
        'id': `${partResult.data.response[i].name}-labels`,
        'type': 'symbol',
        'source': partResult.data.response[i].name,
        'layout': {
          'text-field': partResult.data.response[i].name
        },
        'paint': {
          'text-color': '#fff308'
        }
      });
      map.addLayer({
        'id': `${partResult.data.response[i].name}-borders`,
        'type': 'line',
        'source': partResult.data.response[i].name,
        'layout': {},
        'paint': {
          'line-color': '#fff308',
          'line-width': 3
        }
      });
      map.on('mousemove', partResult.data.response[i].name, function(e){
        map.getCanvas().style.cursor = 'pointer';
        let mapLayer = map.getLayer(`${partResult.data.response[i].name}-borders-onHover`);
        if(typeof mapLayer === 'undefined'){
          map.addLayer({
            'id': `${partResult.data.response[i].name}-borders-onHover`,
            'type': 'line',
            'source': partResult.data.response[i].name,
            'layout': {},
            'paint': {
              'line-color': '#fff308',
              'line-width': 3
            }
          });
        }
      });
      map.on('mouseleave', partResult.data.response[i].name, function(e){
        map.getCanvas().style.cursor = '';
        let mapLayer = map.getLayer(`${partResult.data.response[i].name}-borders-onHover`);
        if(typeof mapLayer !== 'undefined'){
          map.removeLayer(`${partResult.data.response[i].name}-borders-onHover`)
        }
      });
      map.on('click', partResult.data.response[i].name, async function(e){
        setPart(partResult.data.response[i].name);
        handlePartClick(partResult, partResult.data.response[i])
      })
    }
    setMap(map);
  }

  const searchFormSubmit = async (e) => {
    e.preventDefault();
    let options = {
      'headers': {
        'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
      }
    };
    let response = await axios.get(`https://dev.zip.pr/eci/surveyed-epic?epic_no=${searchEpic}`, options);
    if(!response.data.ok){
      setEpicNotFound(`Epic ${searchEpic} not tagged to any building`)
      setSearchEpic('');
      return
    }
    setFilterOpen(false);
    map.flyTo({center: [response.data.response.epicDetails.longitude,response.data.response.epicDetails.latitude], zoom: 20});
    setSearchEpic('');
    setDdnPanel(response.data.response.epicDetails.building_id)
  }

  const handlePartClick = async (partResult, part) => {
    map.flyTo({center: part.center, zoom: 14});
    //looping to remove layers
    for(let j = 0; j < partResult.data.response.length; j++){
      map.removeLayer(`${partResult.data.response[j].name}`);
      map.removeLayer(`${partResult.data.response[j].name}-labels`);
      map.removeLayer(`${partResult.data.response[j].name}-borders`);
    }
    //remove the hovered layer
    let mapLayer = map.getLayer(`${part.name}-borders-onHover`);
    if(typeof mapLayer !== 'undefined'){
      map.removeLayer(`${part.name}-borders-onHover`)
    }

    map.addSource('selectedPart', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [part.coordinates]
        }
      }
    });
    map.addLayer({
      'id': 'selectedPart-border',
      'type': 'line',
      'source': 'selectedPart',
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 3
      }
    })
    
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];

    setInfoPanel(true);
    setInfoType('Section');
    // let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    // let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let sectionResult = await axios.get(`/v1/map/constituency/part/sections?state=${stateParam}&constituency=${constituencyParam}&part=${part.name}&start_date=2020-02-26&end_date=2020-03-30`);
    setSectionResult(sectionResult);
    //add sections
    for(let k = 0; k < sectionResult.data.response.length; k++){
      map.addSource(sectionResult.data.response[k].name, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [sectionResult.data.response[k].coordinates]
          }
        }
      });
      map.addLayer({
        'id': sectionResult.data.response[k].name,
        'type': 'fill',
        'source': sectionResult.data.response[k].name,
        'paint': {
          'fill-color': '#00e',
          'fill-opacity': 0.3
        }
      });
      map.addLayer({
        'id': `${sectionResult.data.response[k].name}-labels`,
        'type': 'symbol',
        'source': sectionResult.data.response[k].name,
        'layout': {
          'text-field': sectionResult.data.response[k].name
        },
        'paint': {
          'text-color': '#fff308'
        }
      })
      map.addLayer({
        'id': `${sectionResult.data.response[k].name}-borders`,
        'type': 'line',
        'source': sectionResult.data.response[k].name,
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });
      map.on('mousemove', sectionResult.data.response[k].name, function(e){
        map.getCanvas().style.cursor = 'pointer';
        let mapLayer = map.getLayer(`${sectionResult.data.response[k].name}-borders-onHover`);
        if(typeof mapLayer === 'undefined'){
          map.addLayer({
            'id': `${sectionResult.data.response[k].name}-borders-onHover`,
            'type': 'line',
            'source': sectionResult.data.response[k].name,
            'layout': {},
            'paint': {
              'line-color': '#fff308',
              'line-width': 3
            }
          });
        }
      });
      map.on('mouseleave', sectionResult.data.response[k].name, function(e){
        map.getCanvas().style.cursor = '';
        let mapLayer = map.getLayer(`${sectionResult.data.response[k].name}-borders-onHover`);
        if(typeof mapLayer !== 'undefined'){
          map.removeLayer(`${sectionResult.data.response[k].name}-borders-onHover`)
        }
      });
      map.on('click', sectionResult.data.response[k].name, function(e){
        setPopUpTop(e.point.y+10);
        setPopUpLeft(e.point.x+10);
        setSectionPopup(true);
        setMergeBox(false);
        setInfoPanel(false);
        setSection(sectionResult.data.response[k].name);
        setI(i);
        setK(k);
      })
    }
    setMap(map);
  }

  const handleSectionEnter = async () => {
    map.flyTo({center: sectionResult.data.response[k].center, zoom: 18});
    // setInfoPanel(true);
    // setInfoType('Sectioninfo');
    setSectionPopup(false);
    for(let l = 0; l < sectionResult.data.response.length; l++){
      map.removeLayer(sectionResult.data.response[l].name);
      map.removeLayer(`${sectionResult.data.response[l].name}-labels`);
      map.removeLayer(`${sectionResult.data.response[l].name}-borders`);
    }
    // //remove the hovered layer
    let mapLayer = map.getLayer(`${sectionResult.data.response[k].name}-borders-onHover`);
    if(typeof mapLayer !== 'undefined'){
      map.removeLayer(`${sectionResult.data.response[k].name}-borders-onHover`)
    } 
    map.addSource('section', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [ sectionResult.data.response[k].coordinates ]
        }
      }
    });
    map.addLayer({
      'id': 'section',
      'type': 'fill',
      'source': 'section',
      'paint': {
        'fill-color': '#00e',
        'fill-opacity': 0.3
      }
    });
    map.addLayer({
      'id': 'section-borders',
      'type': 'line',
      'source': 'section',
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 3
      }
    });
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let roadsData = await axios.get(`/v1/constituency/roads?state=${stateParam}&assembly_constituency=${constituencyParam}&part=${part}&section=${sectionResult.data.response[k].name}`)
    for(let i = 0; i < roadsData.data.Roads.length; i++){
      let roadSource = map.getSource(`${roadsData.data.Roads[i].roadNo}-${i}`);
      if(roadSource){
        map.removeSource(`${roadsData.data.Roads[i].roadNo}-${i}`)
      }
      map.addSource(`${roadsData.data.Roads[i].roadNo}-${i}`, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': roadsData.data.Roads[i].geometry.coordinates
          }
        }
      });
      let roadLayer = map.getLayer(`${roadsData.data.Roads[i].roadNo}-${i}`);
      if(roadLayer){
        map.removeLayer(`${roadsData.data.Roads[i].roadNo}-${i}`)
      }
      map.addLayer({
        'id' : `${roadsData.data.Roads[i].roadNo}-${i}`,
        'type': 'line',
        "source": `${roadsData.data.Roads[i].roadNo}-${i}`,
        "layout": {
          "line-join": "round",
          "line-cap": "round"
        },
        "paint": {
            "line-color": "#1711bd",
            "line-width": 4
        } 
      });
      map.addLayer({
        "id": `${roadsData.data.Roads[i].roadNo}-${i}-label`,
        "type": "symbol",
        "source": `${roadsData.data.Roads[i].roadNo}-${i}`,
        "layout": {
            "symbol-placement": "line",
            "text-font": ["Open Sans Regular"],
            "text-field": `Road No. ${roadsData.data.Roads[i].roadNo}`,
            "text-size": 14
        },
        'paint': {
          'text-color': '#fff308'
        }
      })
    }
    setMap(map);
  }

  const handleSectionEdit = async () => {
    setSectionPopup(false);
    // var draw = new MapboxDraw({
    //   displayControlsDefault: false,
    //   // controls: {
    //   //   polygon: true,
    //   //   trash: true
    //   // }
    // })
    // map.addControl(draw, 'bottom-right');
    setEditingSection(section);
    let sectionCoord = sectionResult.data.response.filter(item => item.name === section).map(item => item.coordinates);
    // map.on('draw.create', createPolygon);
    map.on('draw.update', updatePolygon);
    map.removeLayer(section);
    map.removeLayer(`${section}-borders`);
    map.removeLayer(`${section}-labels`);
    let mapLayer = map.getLayer(`${section}-borders-onHover`);
    if(mapLayer) {
      map.removeLayer(`${section}-borders-onHover`);
    }
    map.removeSource(section)
    let feature = { type: 'Polygon', coordinates: sectionCoord}
    draw.add(feature);
    // setDraw(draw);
  }

  // const createPolygon = (e) => {
  //   console.log(e.features[0].geometry.coordinates);
  // }

  const updatePolygon = (e) => {
    setNewCoord(e.features[0].geometry.coordinates);
  }

  const updateSectionCoord = async () => {
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let params = {
      "state" : stateParam,
      "assembly_constituency" : constituencyParam,
      "part": part,
      "section" : editingSection,
      "new_coordinates": newCoord
    }
    let response = await axios.post('/v1/constituency/edit', params);
    if(response.data.ok){
      setNewCoord(null);
      let result = await axios.get(`/v1/map/constituency/part/sections?state=${stateParam}&constituency=${constituencyParam}&part=${part}&start_date=2020-02-26&end_date=2020-03-30`);
      setSectionResult(result);
      let updatedSection = result.data.response.filter(item => item.name === editingSection);
      map.addSource(updatedSection[0].name, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [updatedSection[0].coordinates]
          }
        }
      });
      map.addLayer({
        'id': updatedSection[0].name,
        'type': 'fill',
        'source': updatedSection[0].name,
        'paint': {
          'fill-color': '#00e',
          'fill-opacity': 0.3
        }
      });
      map.addLayer({
        'id': `${updatedSection[0].name}-labels`,
        'type': 'symbol',
        'source': updatedSection[0].name,
        'layout': {
          'text-field': updatedSection[0].name
        }
      })
      map.addLayer({
        'id': `${updatedSection[0].name}-borders`,
        'type': 'line',
        'source': updatedSection[0].name,
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });
      map.on('mousemove', updatedSection[0].name, function(e){
        map.getCanvas().style.cursor = 'pointer';
        let mapLayer = map.getLayer(`${updatedSection[0].name}-borders-onHover`);
        if(typeof mapLayer === 'undefined'){
          map.addLayer({
            'id': `${updatedSection[0].name}-borders-onHover`,
            'type': 'line',
            'source': updatedSection[0].name,
            'layout': {},
            'paint': {
              'line-color': '#fff308',
              'line-width': 3
            }
          });
        }
      });
      map.on('mouseleave', updatedSection[0].name, function(e){
        map.getCanvas().style.cursor = '';
        let mapLayer = map.getLayer(`${updatedSection[0].name}-borders-onHover`);
        if(typeof mapLayer !== 'undefined'){
          map.removeLayer(`${updatedSection[0].name}-borders-onHover`)
        }
      });
      map.on('click', updatedSection[0].name, function(e){
        setPopUpTop(e.point.y+10);
        setPopUpLeft(e.point.x+10);
        setSectionPopup(true);
        setInfoPanel(false);
        setSection(updatedSection[0].name);
        setI(i);
        setK(k);
      })
    }
    draw.deleteAll();
  }

  const handleSectionMerge = (value) => {
    setMergeBox(false);
    setMergeSuccess(true);
    draw.add(value)
    setMergedPolygonCoord(value.coordinates)
    map.on('draw.update', updateMergePolygon);
  }

  const updateMergePolygon = (e) => {
    setMergedPolygonCoord(e.features[0].geometry.coordinates);
    setMergeSuccess(true);
  }

  const confirmMerge = async () => {
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let params = {
      "state" : stateParam,
      "assembly_constituency" : constituencyParam,
      "part": Number(part),
      "section" : Number(toSection),
      "part_to_merge": Number(fromPart),
			"section_to_merge": Number(fromSection),
      "new_coordinates": mergedPolygonCoord
    }
    let response = await axios.post('/v1/constituency/merge-section', params);
    for(let l = 0; l < sectionResult.data.response.length; l++){
      map.removeLayer(sectionResult.data.response[l].name);
      map.removeLayer(`${sectionResult.data.response[l].name}-labels`);
      map.removeLayer(`${sectionResult.data.response[l].name}-borders`);
      let mapLayer = map.getLayer(`${sectionResult.data.response[l].name}-borders-onHover`);
      if(mapLayer) {
        map.removeLayer(`${sectionResult.data.response[l].name}-borders-onHover`);
      }
      map.removeSource(sectionResult.data.response[l].name)
    }
    let updatedSectionResult = await axios.get(`/v1/map/constituency/part/sections?state=${stateParam}&constituency=${constituencyParam}&part=${part}&start_date=2020-02-26&end_date=2020-03-30`);
    setSectionResult(updatedSectionResult);
    for(let k = 0; k < updatedSectionResult.data.response.length; k++){
      map.addSource(updatedSectionResult.data.response[k].name, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [updatedSectionResult.data.response[k].coordinates]
          }
        }
      });
      map.addLayer({
        'id': updatedSectionResult.data.response[k].name,
        'type': 'fill',
        'source': updatedSectionResult.data.response[k].name,
        'paint': {
          'fill-color': '#00e',
          'fill-opacity': 0.3
        }
      });
      map.addLayer({
        'id': `${updatedSectionResult.data.response[k].name}-labels`,
        'type': 'symbol',
        'source': updatedSectionResult.data.response[k].name,
        'layout': {
          'text-field': updatedSectionResult.data.response[k].name
        }
      })
      map.addLayer({
        'id': `${updatedSectionResult.data.response[k].name}-borders`,
        'type': 'line',
        'source': updatedSectionResult.data.response[k].name,
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });
      map.on('mousemove', updatedSectionResult.data.response[k].name, function(e){
        map.getCanvas().style.cursor = 'pointer';
        let mapLayer = map.getLayer(`${updatedSectionResult.data.response[k].name}-borders-onHover`);
        if(typeof mapLayer === 'undefined'){
          map.addLayer({
            'id': `${updatedSectionResult.data.response[k].name}-borders-onHover`,
            'type': 'line',
            'source': updatedSectionResult.data.response[k].name,
            'layout': {},
            'paint': {
              'line-color': '#fff308',
              'line-width': 3
            }
          });
        }
      });
      map.on('mouseleave', updatedSectionResult.data.response[k].name, function(e){
        map.getCanvas().style.cursor = '';
        let mapLayer = map.getLayer(`${updatedSectionResult.data.response[k].name}-borders-onHover`);
        if(typeof mapLayer !== 'undefined'){
          map.removeLayer(`${updatedSectionResult.data.response[k].name}-borders-onHover`)
        }
      });
      map.on('click', updatedSectionResult.data.response[k].name, function(e){
        setPopUpTop(e.point.y+10);
        setPopUpLeft(e.point.x+10);
        setSectionPopup(true);
        setMergeBox(false);
        setInfoPanel(false);
        setSection(updatedSectionResult.data.response[k].name);
        setI(i);
        setK(k);
      })
    }
    setMergeSuccess(false);
    draw.deleteAll();
  }

  const handleCalendarOpen = (edge) => {
    setSelectEdge(edge);
  }

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  }

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  }

  const handleCalendarAccept = (date) => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      }
    }

    setSelectEdge(null);
  }

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
    setInfoPanel(false);
  }

  const handleFilterClose = () => {
    setFilterOpen(false);
  }

  console.log(fromPart);

  return (
    <div style={{position: 'relative'}}>
      <div ref={el => mapContainer.current = el} style={{height: '93.1vh', width: '100%', position: 'absolute', top: -10}} />
      {/* <div className={classes.searchBar}>
        <div className={classes.searchBarRight}>
          <div
            className={classes.search}
          >
            <SearchIcon className={classes.searchIcon}/>
            <Input
              className={classes.searchInput}
              disableUnderline
              placeholder="Search"
            />
          </div>
          <div 
            className={classes.filterButton}
            onClick={handleFilter}
          >
            <FilterListRoundedIcon />
          </div>
        </div>
      </div> */}
      <div className={classes.searchBar}>
        <div className={classes.searchBarLeft}>
          {epicNotFound}
        </div>
        <div className={classes.searchBarRight}>
          <form onSubmit={searchFormSubmit}>
            <div
              className={classes.search}
            >
              <SearchIcon className={classes.searchIcon}/>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search"
                value={searchEpic}
                onChange={(e) => {
                  setEpicNotFound('');
                  setSearchEpic(e.target.value)}
                }
              />
            </div>
          </form>
          <div 
            className={classes.filterButton}
            onClick={handleFilter}
          >
            <FilterListRoundedIcon />
          </div>
        </div>
      </div>
      <InfoPanel state={state} constituency={constituency} part={part} section={section} infoPanel={infoPanel} infoType={infoType} handle={setInfoPanel} constituencyResult={constituencyResult} partResult={partResult} sectionResult={sectionResult} section={section} constituencyClick={() => handleConstituencyClick(constituencyResult)} partClick={handlePartClick}/>
      <DDNPanel refresh={refreshInfo} setRefresh={setRefreshInfo} ddnPanel={ddnPanel} handle={setDdnPanel} edit={setBuildingAddress} epicAdd={setEpicAddBox} epicBuildingId={setEpicBuildingId} epicState={setEpicState} epicAssembly={setEpicAssembly} epicParentId={setEpicParentId} epicFamilyId={setEpicFamilyId} epicEdit={setEpicEditBox} epicDelete={setEpicDelete}/>
      <EditBox setRefresh={setRefreshInfo} building={ddnPanel} editBox={buildingAddress} handle={setBuildingAddress}/>
      <EditSectionBox editing={newCoord} handle={setNewCoord} submit={updateSectionCoord}/>
      <Backdrop show={buildingAddress} show1={epicAddBox} show2={epicEditBox} handle={setBuildingAddress}/>
      <EpicInfoDetails show={epicEditBox} handle={setEpicEditBox} building={epicBuildingId} state={epicState} assembly={epicAssembly} parentId={epicParentId} familyId={epicFamilyId}/>
      <EpicDelete show={epicDelete} handle={setEpicDelete}/>
      <SectionPopup sectionPopup={sectionPopup} top={popUpTop} left={popUpLeft} section={section} toSection={setToSection} handle={setSectionPopup} enter={() => handleSectionEnter()} merge={setMergeBox} edit={handleSectionEdit} manage={manage} setManage={setManage}/>
      <MergeBox state={state} constituency={constituency} part={part} section={section} fromSection={setFromSection} fromPart={setFromPart} mergeBox={mergeBox} handle={setMergeBox} manage={setManage} submitHandle={handleSectionMerge}/>
      <MergeSuccess success={mergeSuccess} handle={setMergeSuccess} submit={confirmMerge}/>
      <div 
        className={filterOpen ? classes.filter : classes.collapseFilter}
      >
        <div 
          className={classes.closeFilter}
          onClick={handleFilterClose}
        >
          <CloseIcon/>
          <Typography>
            Close
          </Typography>
        </div>
        <ListSubheader
          style={{paddingLeft: '5px'}}
        >
          FILTERS
        </ListSubheader>
        <div className={classes.filterSection}>
          <div className={classes.datePicker}>
            <Typography>
              Date
            </Typography>
            <ButtonGroup
              variant="contained"
            >
              <Button
                className={classes.calendarButton}
                onClick={() => handleCalendarOpen('start')}
              >
                <CalendarTodayIcon className={classes.calendarTodayIcon} />
                {startDate.format('DD MM YYYY')}
              </Button>
              <Button
                className={classes.calendarButton}
                onClick={() => handleCalendarOpen('end')}
              >
                <CalendarTodayIcon className={classes.calendarTodayIcon} />
                {endDate.format('DD MM YYYY')}
              </Button>
            </ButtonGroup>
          </div>
          <div>
            <div className={classes.selectGroup}>
              <TextField
                  className={classes.stateSelect}
                  fullWidth
                  margin="dense"
                  name="State"
                  variant="outlined"
                  disabled={false}
                  select
                  onChange={(e) => {
                    setError(false);
                    setState(e.target.value)
                  }}
                  defaultValue=""
              >
                <option
                    disabled
                    value=""
                />
                {states && states.map((option) => (
                    <option
                      className={classes.optionStyle}
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                ))}
              </TextField>
            </div>
            <div className={classes.selectGroup}>
              <TextField
                  className={classes.stateSelect}
                  fullWidth
                  margin="dense"
                  name="Constituency"
                  variant="outlined"
                  disabled={false}
                  select
                  onChange={(e) => {
                    setError(false);
                    setConstituency(e.target.value)}
                  }
                  defaultValue=""
              >
                <option
                    disabled
                    value=""
                />
                { constituencies && constituencies.map((option) => (
                    <option
                    className={classes.optionStyle}
                    key={option}
                    value={option}
                    >
                    {option}
                    </option>
                ))}
              </TextField>
            </div>
          </div>
          { error ? <div className={classes.error}>
            Constituency Not Found!
          </div> : null}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={setLocation}
          >
            Apply
          </Button>
        </div>
      </div>
      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />
    </div>
  );
}

export default Map;
