import mock from 'src/utils/mock';

mock.onGet('/api/overview/state').reply(200, {
  orders: [
    {
        constituency: "Kurnool",
        buildings: 13456,
        epics: 23210
    },
    {
        constituency: "Anantapur",
        buildings: 11567,
        epics: 22120
    },
    {
        constituency: "Kadapa",
        buildings: 9765,
        epics: 13210
    },
    {
        constituency: "Guntur",
        buildings: 10765,
        epics: 11210
    },
    {
        constituency: "Chittor",
        buildings: 8907,
        epics: 12210
    },
    {
        constituency: "East Godavari",
        buildings: 7809,
        epics: 23210
    },
    {
        constituency: "West Godavari",
        buildings: 5467,
        epics: 3210
    },
    {
        constituency: "Vishakhapatanam",
        buildings: 6134,
        epics: 23210
    },
    {
        constituency: "Vizianagaram",
        buildings: 2045,
        epics: 2210
    }
  ]
});

mock.onGet('/api/overview/constituency').reply(200, {
    orders: [
      {
          name: "Part-01",
          buildings: 3456,
          epics: 3210
      },
      {
          name: "Part-02",
          buildings: 1567,
          epics: 2120
      },
      {
          name: "Part-03",
          buildings: 765,
          epics: 210
      },
      {
          name: "Part-04",
          buildings: 1765,
          epics: 1210
      },
      {
          name: "Part-05",
          buildings: 907,
          epics: 1210
      },
      {
          name: "Part-06",
          buildings: 809,
          epics: 3210
      },
      {
          name: "Part-07",
          buildings: 467,
          epics: 3210
      },
      {
          name: "Part-08",
          buildings: 634,
          epics: 2210
      },
      {
          name: "Part-09",
          buildings: 2045,
          epics: 2210
      }
    ]
});

mock.onGet('/api/overview/part').reply(200, {
    orders: [
        {
            name: "Section-01",
            buildings: 456,
            epics: 210
        },
        {
            name: "Section-02",
            buildings: 117,
            epics: 220
        },
        {
            name: "Section-03",
            buildings: 65,
            epics: 131
        },
        {
            name: "Section-04",
            buildings: 165,
            epics: 112
        },
        {
            name: "Section-05",
            buildings: 97,
            epics: 122
        },
        {
            name: "Section-06",
            buildings: 80,
            epics: 2010
        },
        {
            name: "Section-07",
            buildings: 67,
            epics: 310
        },
        {
            name: "Section-08",
            buildings: 134,
            epics: 97
        },
        {
            name: "Section-09",
            buildings: 45,
            epics: 210
        }
    ]
});
