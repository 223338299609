import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, TextField, Grid, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
var request = require('request');

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    position: 'absolute',
    left: 300,
    top: 200,
    width: '600px',
    backgroundColor: 'white',
    padding: 0,
    paddingBottom: '5px',
    transform: 'translateX(150)',
    transform: 'translateY(100)',
    zIndex: 10,
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  header: {
    width: '100%',
    height: '50px',
    display: 'flex',
    borderBottom: '1px solid #e4f0ef',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  form: {
    padding: '10px 15px',
    borderBottom: '1px solid #e4f0ef',
  },
  form_field: {
    display: 'flex',
    alignItems: 'center'
  },
  footer: {
    float: 'right',
    fontFamily: 'Roboto',
    paddingRight: '20px',
    paddingTop: '5px'
  },
  footer_right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer'
    }
  }
}));

const EditBox = (props) => {
  const classes = useStyles();
  const [address, setAddress] = useState("");

  useEffect(() => {
    setAddress(props.editBox)
  },[props.editBox])

  const handleEditClose = () => {
    props.handle("");
  }

  const submitChanges = async () => {
    var options = {
        'method': 'PUT',
        'url': 'https://dev.zip.pr/eci/address',
        'headers': {
            'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "building_id": props.building,
            "address": address
        })
    };
    request(options, function (error, response) { 
        if (error) throw new Error(error);
        if (JSON.parse(response.body).ok) {
            handleEditClose();
            props.setRefresh(true);
        }
    }); 
  }

  return (
    <div 
    className={props.editBox ? classes.edit : classes.collapse}
    >
        <div className={classes.header}>
            <Typography
                style={{fontWeight: 'bold'}}
            >
                Edit Address
            </Typography>
            <CloseIcon style={{fontSize: '17px', opacity: '0.3'}} onClick={handleEditClose}/>
        </div>
        <div className={classes.form}>
            <Grid
                container
                spacing={2}
                className={classes.form_field}
            >
                <Grid
                    item
                    xs={3}
                >
                    <Typography
                        style={{fontWeight: 'bold'}}
                    >
                        Address
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={9}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="address"
                        value={address}
                        variant="outlined"
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </Grid>
            </Grid>
        </div>
        <div className={classes.footer}>
            <div className={classes.footer_right}>
                <Typography
                    style={{fontSize: '14px', fontWeight: 'bold', marginRight: '20px'}}
                    onClick={handleEditClose}
                >
                    CLOSE
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={submitChanges}
                >
                    SAVE CHANGES
                </Button>
            </div>
        </div>
    </div>
  );
}

export default EditBox;
