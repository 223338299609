import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  edit: {
    position: 'absolute',
    left: 300,
    top: 200,
    height: '190px',
    width: '600px',
    backgroundColor: 'white',
    padding: 0,
    transform: 'translateX(150)',
    transform: 'translateY(100)',
    zIndex: 10,
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapse: {
    display: 'none'
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  header: {
    width: '100%',
    height: '50px',
    display: 'flex',
    borderBottom: '1px solid #e4f0ef',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  text: {
    padding: '20px',
    marginTop: '15px',
    paddingBottom: '38px',
    borderBottom: '1px solid #e4f0ef',
    textAlign: 'center',
    fontFamily: 'Roboto'
  },
  footer: {
    float: 'right',
    fontFamily: 'Roboto',
    paddingRight: '20px',
    paddingTop: '5px'
  },
  footer_right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer'
    }
  }
}));

const EpicDelete = (props) => {
  const classes = useStyles();

  const handleEditClose = () => {
    props.handle(false);
  }

  return (
    <div 
    className={props.show ? classes.edit : classes.collapse}
    >
        <div className={classes.header}>
            <Typography
                style={{fontWeight: 'bold'}}
            >
                Delete EPIC
            </Typography>
            <CloseIcon style={{fontSize: '17px', opacity: '0.3'}} onClick={handleEditClose}/>
        </div>
        <div className={classes.text}>
            <p>
                Are you sure, {props.show} will be removed permanently.
            </p>
        </div>
        <div className={classes.footer}>
            <div className={classes.footer_right}>
                <Typography
                    style={{fontSize: '14px', fontWeight: 'bold', marginRight: '20px'}}
                    onClick={handleEditClose}
                >
                    CLOSE
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                >
                    DELETE
                </Button>
            </div>
        </div>
    </div>
  );
}

export default EpicDelete;
