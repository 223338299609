const parts = ["Part-01",
               "Part-02",
               "Part-03",
               "Part-04",
               "Part-05",
               "Part-06",
               "Part-07",
               "Part-08",
               "Part-09"]

export default parts;