import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '1500px',
    height: '91.9vh',
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: 9
  },
  collapse: {
    display: 'none'
  }
}));

const Backdrop = (props) => {
  const classes = useStyles();

  const closeBackdrop = () => {
    props.handle(false);
  }

  return (
    <div 
        className={props.show || props.show1 || props.show2 ? classes.backdrop : classes.collapse}
        onClick={closeBackdrop}
    >      
    </div>
  );
}

export default Backdrop;
