import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AddLocationIcon from '@material-ui/icons/AddLocation';

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    position: 'absolute',
    right: 40,
    top: '75vh',
    height: '90px',
    width: '90px',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 50,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const AddBuildingButton = (props) => {
  const classes = useStyles();

  return (
    <div 
    className={classes.info}
    onClick={() => props.addHandle(true)}
    >
      <AddLocationIcon style={{color: 'blue', fontSize: '50px'}}/>
    </div>
  );
}

export default AddBuildingButton;
