import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import request from 'request';
import {
    Button,
    ListSubheader,
    TextField,
    Grid
  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  epic: {
    position: 'absolute',
    top: 5,
    left: 20,
    width: '79vw',
    height: '710px',
    zIndex: 9
  },
  collapse: {
    display: 'none'
  },
  box_1: {
    backgroundColor: 'rgba(255,255,255)',
    marginBottom: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: 2,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  box_2: {
    backgroundColor: 'rgba(255,255,255)',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    borderRadius: 2,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  submitButton: {
    textAlign: 'right',
    marginTop: '20px'
  },
  searchButton: {
    textAlign: 'right',
    marginTop: '10px',
    marginBottom: '10px'
  },
  error: {
    fontFamily: 'Roboto',
    color: 'rgb(230, 39, 39)' 
  },
  epicError: {
    fontFamily: 'Roboto',
    color: 'rgb(230, 39, 39)',
    backgroundColor: 'rgba(255,255,255)',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderRadius: 2,
    border: '1px solid rgba(0,0,0,0.2)'
  }
}));

const EpicAddDetails = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState(null);
  const [epicNo, setEpicNo] = useState("");
  const [epicNotFoundError, setEpicNotFoundError] = useState("");
  const [error, setError] = useState('');

  const getEpicDetails = async () => {
    setDetails(null);
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let result = await axios.get(`https://dev.zip.pr/eci/epic?epic_no=${epicNo}`, options);
    if(result.data.ok){
        setDetails(result.data.response.epic_obj);
    } else {
        setEpicNotFoundError(true);
    }
  }

  const submitParentHandler = async () => {
    var options = {
        'method': 'POST',
        'url': 'https://dev.zip.pr/eci/epic-object',
        'headers': {
          'Content-Type': 'application/json',
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        },
        body: JSON.stringify({
            "building_id" : props.building,
            "state": props.state,
            "assembly_constituency" : props.assembly,
            "address" : details.ADDRESS,
            "parent_epic_obj" : {
                "epic_no": epicNo,
                "part" : details.PART_NO,
                "section" : details.SECTION_NO,
                "name": details.FM_NAME_EN,
                "father_husband_name": details.RLN_FM_NM_EN,
                "age": details.AGE,
                "gender": details.GENDER,
                "ero_id": localStorage.getItem('user'),
                "serial_no": JSON.stringify(details.SLNO_INPART),
            }
        })
      };
      request(options, function (error, response) { 
        // if (error) throw new Error(error);
        if(JSON.parse(response.body).response.message){
            setError(JSON.parse(response.body).response.message)
        } else if(JSON.parse(response.body).ok){
            setEpicNo("");
            setDetails(null);
            props.handle(false);
            props.refreshHandle(true);
        }
      });
  }

  const submitFamilyHandler = async () => {
    var options = {
        'method': 'POST',
        'url': 'https://dev.zip.pr/eci/family-object',
        'headers': {
          'Content-Type': 'application/json',
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        },
        body: JSON.stringify({
            "building_id" : props.building,
            "state": props.state,
            "assembly_constituency" : props.assembly,
            "address" : details.ADDRESS,
            "parent_epic": props.parentId,
            "family_epic_obj" : {
                "epic_no": epicNo,
                "part" : details.PART_NO,
                "section" : details.SECTION_NO,
                "name": details.FM_NAME_EN,
                "father_husband_name": details.RLN_FM_NM_EN,
                "age": details.AGE,
                "gender": details.GENDER,
                "ero_id": localStorage.getItem('user'),
                "serial_no": JSON.stringify(details.SLNO_INPART),
            }
        })
      };
      request(options, function (error, response) { 
        if (JSON.parse(response.body).response && JSON.parse(response.body).response.message){
            setError(JSON.parse(response.body).response.message);
        } else if (JSON.parse(response.body).ok) {
            setEpicNo("");
            setDetails(null);
            props.handle(false);
            props.refreshHandle(true);
        } else {
            setError(JSON.parse(response.body).error.reason);
        }
      });
  }

  return (
    <div 
        className={props.show ? classes.epic : classes.collapse}
    >
        <CloseIcon 
            style={{fontSize: '14px',position: 'absolute', top: 2, right: 2}}
            onClick={()=> {
                setError('');
                setEpicNotFoundError('');
                setEpicNo("");
                setDetails(null);
                props.handle(false)
            }}
        />
        <div className={classes.box_1}>
            <Grid
                container
            >
                <Grid
                    item
                    xs={3}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', fontSize: '20px', fontWeight: 'bold', color: 'rgba(0,0,0)'}}
                    >
                        Add {props.show} Epic
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={9}
                >           
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        {props.show} Epic No<span style={{color: 'red'}}>*</span>
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        value={epicNo}
                        onChange={(e) => {
                            setEpicNotFoundError(false);
                            setError('');
                            setDetails(null);
                            setEpicNo(e.target.value)}
                        }
                    />
                </Grid>
            </Grid>
            <div className={classes.searchButton}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={getEpicDetails}
                >
                    Search
                </Button>
            </div>
        </div> 
        {epicNotFoundError ? <div className={classes.epicError}>
                Epic Not found
        </div> : null }
        {details ?
        <div className={classes.box_2}>
            <Grid
                container
            >
                {props.show === "Family" ? <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Parent Epic No<span style={{color: 'red'}}>*</span>
                    </ListSubheader>
                </Grid> : null }
                {props.show === "Family" ? <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={props.parentId}
                    />
                </Grid> : null }
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Building Id
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={props.building}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        State
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={props.state}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Assembly Constituency
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={props.assembly}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Address
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.ADDRESS}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Name
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.FM_NAME_EN}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Age
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.AGE}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Gender
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.GENDER}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Father/Husband Name
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.RLN_FM_NM_EN}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Part Number
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.PART_NO}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Section Number
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.SECTION_NO}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <ListSubheader
                    style={{paddingLeft: '5px', color: 'rgba(0,0,0)'}}
                    >
                        Serial No
                    </ListSubheader>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <TextField
                        fullWidth
                        margin="dense"
                        name="buildingName"
                        variant="outlined"
                        disabled
                        value={details.SLNO_INPART}
                    />
                </Grid>
            </Grid>
            {error ? <div className={classes.epicError}>
                {error}
            </div> : null }
            <div className={classes.submitButton}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={props.show !== "Family" ? submitParentHandler : submitFamilyHandler}
                >
                    Submit
                </Button>
            </div>
        </div> : null }      
    </div>
  );
}

export default EpicAddDetails;
