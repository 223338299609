import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {},
  popup: {
    position: 'absolute',
    right: 400,
    top: 400,
    // height: '120px',
    width: '120px',
    backgroundColor: 'white',
    padding: 0,
    borderRadius: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '20px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 3,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)'
  },
  collapsePopup: {
    display: 'none'
  },
  closePopup: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  list: {
    listStyle: 'none'
  },
  listitem: {
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: '20px',
    color: '#78807f',
    '&:hover': {
        cursor: 'pointer'
    }
  }
}));

const SectionPopup = (props) => {
  const classes = useStyles();

  const handleFilterClose = () => {
    props.handle(false);
    props.setManage(false);
  }

  const handleMerge = () => {
    props.toSection(props.section)
    props.merge(true);
    props.handle(false);
  }

  return (
    <div 
    className={props.sectionPopup ? classes.popup : classes.collapsePopup}
    style={{top: props.top, left: props.left}}
    >
        <div 
            className={classes.closePopup}
            onClick={handleFilterClose}
        >
            <CloseIcon style={{fontSize: '10px', marginLeft: '-4px'}}/>
        </div>
        <div>
            <h4 style={{fontFamily: 'Roboto', textAlign: 'center'}}>{props.section}</h4>
            <ul className={classes.list}>
                <li>
                    <div 
                        className={classes.listitem}
                        onClick={handleMerge}
                    >
                        <CallMergeIcon style={{fontSize: '14px', marginRight: '10px'}}/> <h4>Merge</h4>
                    </div>
                </li>
                <li>
                    <div 
                        className={classes.listitem}
                        onClick={props.enter}
                    >
                        <VisibilityIcon style={{fontSize: '14px', marginRight: '10px'}}/> <h4>Show DDNs</h4>
                    </div>
                </li>
                <li>
                    <div 
                        className={classes.listitem}
                        onClick={props.edit}
                    >
                        <EditIcon style={{fontSize: '14px', marginRight: '10px'}}/> <h4>Edit</h4>
                    </div>
                </li>
            </ul> 
        </div>
    </div>
  );
}

export default SectionPopup;
