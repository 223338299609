import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import fmt from 'indian-number-format';
import Page from 'src/components/Page';
import Header from './Header';
import Overview from './Overview';
import LatestOrders from './LatestOrders';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function DashboardAnalytics() {
  const classes = useStyles();
  const [selectedState, setSelectedState] = useState('');
  const [buildings, setBuildings] = useState(null);
  const [taggedBuildings, setTaggedBuildings] = useState(null);
  const [epics, setEpics] = useState(null);
  const [constituencyReport, setConstituencyReport] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    getStateOverview();
  }, [selectedState, startDate, endDate]);

  const getStateOverview = async () => {
    let options = {
      'headers': {
        'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
      }
    };
    if(!selectedState){
      let state = localStorage.getItem('state');
      setSelectedState(state);
    }
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[selectedState];
    let response = await axios.get(`https://dev.zip.pr/eci/state?state=${stateParam}&start_date=${startDate.format('DD-MM-YYYY')}&end_date=${endDate.format('DD-MM-YYYY')}`, options);
    if(!response.data.ok) return
    setBuildings(fmt.format(response.data.response.snapshot.totalBuildings));
    setEpics(fmt.format(response.data.response.snapshot.totalEpics))
    setConstituencyReport(response.data.response.ACWiseCounts);
  }

  useEffect(() => {
    let taggedBuildings = 0;
    if(constituencyReport){
      for(let i = 0; i < constituencyReport.length; i++){
        taggedBuildings += constituencyReport[i].surveyedBuildings
      }
      setTaggedBuildings(taggedBuildings)
    }
  },[constituencyReport])

  return (
    <Page
      className={classes.root}
      title="Analytics Dashboard"
    >
      <Container maxWidth={false}>
        <Header state={selectedState} stateHandle={setSelectedState} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Overview buildings={taggedBuildings} epics={epics} />
          </Grid>
          <Typography
            component="h2"
            gutterBottom
            variant="h3"
            style={{textTransform: 'none', marginLeft: '9px'}}
          >
            State Level Report
          </Typography>
          <Grid
            item
            xs={12}
          >
            <LatestOrders report={constituencyReport} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default DashboardAnalytics;
