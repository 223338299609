import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {},
  infoText: {
    margin: '8px',
    fontFamily: 'Roboto',
    fontSize: '14px'
  }
}));

const InfoBox = (props) => {
  const classes = useStyles();
  const [buildings, setBuildings] = useState(null);
  const [count, setCount] = useState(null);
  const [partCount, setPartCount] = useState(null);
  const [sectionCount, setSectionCount] = useState(null);
  const [section, setSection] = useState(null);

  const getConstituencyCount = async () => {
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
    let response = await axios.get(`https://dev.zip.pr/eci/ac-counts?state=${stateParam}&assembly_constituency=${constituencyParam}`, options);
    setBuildings(response.data.response.buildingsCount)
    setCount(response.data.response.epicsCount);
  }

  const getPartCount = async () => {
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
    let response = await axios.get(`https://dev.zip.pr/eci/parts-count?state=${stateParam}&assembly_constituency=${constituencyParam}`, options);
    setPartCount(response.data.response.partsCount);
  }

  const getSectionCount = async () => {
    let options = {
        'headers': {
          'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
        }
    };
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[props.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[props.state];
    let response = await axios.get(`https://dev.zip.pr/eci/section-count?state=${stateParam}&assembly_constituency=${constituencyParam}&part=${props.part}`, options);
    setSectionCount(response.data.response.sectionCounts);
  }

  const getSingleSectionCount = async () => {
    if(sectionCount){
        let singleSectionCount = sectionCount.filter(item => item.section === Number(props.section));
        setSection(singleSectionCount);
    }
  }

  useEffect(() => {
    switch (props.infoType){
        case 'Constituency':
            getConstituencyCount()
            break;
        case 'Part':
            getPartCount()
            break;
        case 'Section':
            getSectionCount()
            break;
        case 'Sectioninfo':
            getSingleSectionCount()
            break;
    }
  },[props.infoType])

  switch (props.infoType) {
    case 'Constituency':
        return (
            <div>
                <div className={classes.infoText}>
                    <a href="#">{props.constituency}</a> : {buildings}({count})
                </div>
            </div>
        );
    case 'Part':
        return (
            <div>
                {partCount && partCount.map(item => {
                    return (
                        <div className={classes.infoText}>
                            <a href="#">Part {item.part}</a> : {item.buildingsCount}({item.totalEpics})
                        </div>
                    )
                })}
            </div>
        )
    case 'Section':
        return (
            <div>
                {sectionCount && sectionCount.map(item => {
                    return (
                        <div className={classes.infoText}>
                            <a href="#">Section {item.section}</a> : {item.buildingsCount}({item.totalEpics})
                        </div>
                    )
                })}
            </div>
        )
    case 'Sectioninfo':
        return (
            <div>
                {section && section.map(item => {
                    return (
                        <div className={classes.infoText}>
                            <a href="#">Section {item.section}</a> : {item.totalEpics}
                        </div>
                    )
                })}
            </div>
        )
    default:
        return (
            <>
            </>
        )
  }
}

export default InfoBox;
