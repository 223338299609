import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input
} from '@material-ui/core';
import * as _ from 'lodash';
import fmt from 'indian-number-format';
import GenericMoreButton from 'src/components/GenericMoreButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  progressContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  searchBox: {
    position: 'absolute',
    left: '50%',
    top: '10px',
    transform: 'translateX(-50%)'
  },
  searchIcon: {
    position: 'absolute',
    top: '5px'
  }
}));

function LatestOrders({ className, ...rest }) {
  const classes = useStyles();
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    setOrders(transform(rest.report))
  }, [rest.report]);

  const transform = (report) => {
    return _.sortBy(report, ['part']);
  }

  const inputHandler = async (value) => {
    let filteredReport = rest.report.filter(item => JSON.stringify(item.part).toLowerCase().includes(value.toLowerCase()) );
    setOrders(filteredReport);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.searchBox}>
        <Input onChange={(e) => inputHandler(e.target.value)} placeholder="Search here..."/>
        <SearchIcon className={classes.searchIcon}/>
      </div>
      <CardHeader
        action={<GenericMoreButton />}
        title="Part Report"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            {orders && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Part</TableCell>
                    {/* <TableCell align="right">Total Buildings</TableCell> */}
                    <TableCell align="right">Surveyed Buildings</TableCell>
                    <TableCell align="right">ERO Created EPICs</TableCell>
                    <TableCell align="right">BLO Verified EPICs</TableCell>
                    <TableCell align="right">BLO Created EPICs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map(order => (
                    <TableRow
                      hover
                      key={order.name}
                    >
                      <TableCell align="left">{order.part}</TableCell>
                      {/* <TableCell align="right">{fmt.format(order.buildings)}</TableCell> */}
                      <TableCell align="right">{fmt.format(order.surveyedBuildings)}</TableCell>
                      <TableCell align="right">{fmt.format(order.EROEpics)}</TableCell>
                      <TableCell align="right">{fmt.format(order.BLOVerifiedEpics)}</TableCell>
                      <TableCell align="right">{fmt.format(order.BLOEpics)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
