import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = (props) => {
    let token = localStorage.getItem('token');
    if(!token){
        return <Redirect to='/auth/login' />
    }
    if(token){
        return (
            <>
                {props.children}
            </>
        )
    }
}

export default PrivateRoute