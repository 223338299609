/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import MapIcon from '@material-ui/icons/Map';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AssessmentIcon from '@material-ui/icons/Assessment';

export default [
  {
    subheader: 'MENU',
    items: [
      // {
      //   title: 'Overview',
      //   href: '/overview',
      //   icon: DashboardIcon,
      //   items: [
      //     {
      //       title: 'State Level Report',
      //       href: '/overview/state'
      //     },
      //     {
      //       title: 'Constituency Level Report',
      //       href: '/overview/constituency'
      //     },
      //     {
      //       title: 'Part Level Report',
      //       href: '/overview/part'
      //     }
      //   ]
      // },
      // {
      //   title: 'Map view',
      //   href: '/map',
      //   icon: MapIcon
      // },
      // {
      //   title: 'Management',
      //   href: '/management',
      //   icon: BarChartIcon,
      //   items: [
      //     {
      //       title: 'Lock/Unlock Constituency',
      //       href: '/management/lock'
      //     }
      //   ]
      // },
      // {
      //   title: 'Epic Data Entry',
      //   href: '/epic',
      //   icon: ViewModuleIcon
      // },
      {
        title: 'Daily Reports',
        href: '/dashboard',
        icon: ViewModuleIcon
      },
      {
        title: 'Download',
        href: '/download',
        icon: AssessmentIcon
      }
    ]
  }
];
