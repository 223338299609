import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, ButtonGroup, Menu, MenuItem, ListItemText } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  root: {},
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  dropdownButton: {
    backgroundColor: theme.palette.common.white
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ className, ...rest }) {
  const classes = useStyles();
  const stateRef = useRef(null);
  const constituencyRef = useRef(null);
  const partRef = useRef(null);
  const [states, setStates] = useState(null);
  const [openState, setOpenState] = useState(false);
  const [constituencies, setConstituencies] = useState(null);
  const [openConstituency, setOpenConstituency] = useState(false);
  const [parts, setParts] = useState(null);
  const [openPart, setOpenPart] = useState(false);
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const open = Boolean(selectEdge);

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let states = assigned_constituencies.map(item => item.state_display_name);
    setStates(states);
  },[])

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let state_constituencies = assigned_constituencies.filter(item => item.state_display_name === rest.state);
    let constituencies = state_constituencies.map(item => item.ac_display_name);
    setConstituencies(constituencies);
  },[rest.state])

  useEffect(() => {
    getPartsList()
  },[rest.constituency])

  const getPartsList = async () => {
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[rest.constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[rest.state];
    let response = await axios.get(`/v1/constituency/parts-list?state=${stateParam}&assembly_constituency=${constituencyParam}`);
    setParts(response.data.partsList);
    rest.partHandle(response.data.partsList[0]);
  }

  const handleStateOpen = () => {
    setOpenState(true);
  };

  const handleStateClose = () => {
    setOpenState(false);
  };

  const handleStateSelect = (value) => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let state_constituencies = assigned_constituencies.filter(item => item.state_display_name === value);
    let constituencies = state_constituencies.map(item => item.ac_display_name);
    rest.constituencyHandle(constituencies[0]);
    rest.stateHandle(value);
    setOpenState(false);
  };

  const handleConstituencyOpen = () => {
    setOpenConstituency(true);
  };

  const handleConstituencyClose = () => {
    setOpenConstituency(false);
  };

  const handleConstituencySelect = (value) => {
    rest.constituencyHandle(value);
    setOpenConstituency(false);
  };

  const handlePartOpen = () => {
    setOpenPart(true);
  };

  const handlePartClose = () => {
    setOpenPart(false);
  };

  const handlePartSelect = (value) => {
    rest.partHandle(value);
    setOpenPart(false);
  };

  const handleCalendarOpen = (edge) => {
    setSelectEdge(edge);
  }

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  }

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  }

  const handleCalendarAccept = (date) => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      rest.setStartDate(date);

      if (moment(date).isAfter(rest.endDate)) {
        rest.setEndDate(date);
      }
    } else {
      rest.setEndDate(date);

      if (moment(date).isBefore(rest.startDate)) {
        rest.setStartDate(date);
      }
    }

    setSelectEdge(null);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          lg={3}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
            style={{textTransform: 'none', marginBottom: '6%'}}
          >
            Analytics
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
            style={{marginBottom: '7%'}}
          >
            SNAPSHOT
          </Typography>
        </Grid>
        <Grid
          className={classes.calendar}
          item
          lg={9}
          xs={12}
        >
          <ButtonGroup
            variant="contained"
            style={{marginRight: '3%'}}
          >
            <Button 
              className={classes.dropdownButton}
              style={{textTransform: 'none'}}
              onClick={handlePartOpen}
              ref={partRef}
            >
              {rest.part}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={partRef.current}
              className={classes.menu}
              onClose={handlePartClose}
              open={openPart}
              elevation={1}
            >
              {parts && parts.map(
                (option) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handlePartSelect(option)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                )
              )}
            </Menu>
          </ButtonGroup>
          <ButtonGroup
            variant="contained"
            style={{marginRight: '3%'}}
          >
            <Button 
              className={classes.dropdownButton}
              style={{textTransform: 'none'}}
              onClick={handleConstituencyOpen}
              ref={constituencyRef}
            >
              {rest.constituency}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={constituencyRef.current}
              className={classes.menu}
              onClose={handleConstituencyClose}
              open={openConstituency}
              elevation={1}
            >
              {constituencies && constituencies.map(
                (option) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleConstituencySelect(option)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                )
              )}
            </Menu>
          </ButtonGroup>
          <ButtonGroup
            variant="contained"
            style={{marginRight: '3%'}}
          >
            <Button 
              className={classes.dropdownButton}
              style={{textTransform: 'none'}}
              onClick={handleStateOpen}
              ref={stateRef}
            >
              {rest.state}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={stateRef.current}
              className={classes.menu}
              onClose={handleStateClose}
              open={openState}
              elevation={1}
            >
              {states && states.map(
                (option) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleStateSelect(option)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                )
              )}
            </Menu>
          </ButtonGroup>
          <ButtonGroup
            variant="contained"
          >
            <Button
              className={classes.calendarButton}
              onClick={() => handleCalendarOpen('start')}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {rest.startDate.format('DD MM YYYY')}
            </Button>
            <Button
              className={classes.calendarButton}
              onClick={() => handleCalendarOpen('end')}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {rest.endDate.format('DD MM YYYY')}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
