import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import {
  Button,
  Input,
  ListSubheader,
  TextField,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseIcon from '@material-ui/icons/Close';
import mapboxgl from 'mapbox-gl';
import DDNPanel from './DDNPanel';
import Backdrop from './backdrop';
import EpicAddDetails from './epicAddDetails';
import EpicDelete from './epicDelete';
import AddBuildingButton from './AddBuildingButton';
import AddBuildingModal from './AddBuildingModal';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';

const useStyles = makeStyles((theme) => ({
  root: {},
  searchBar: {
    position: 'absolute', 
    top: 0, 
    left: 0, 
    zIndex: 2, 
    backgroundColor: 'rgba(255,255,255,0.7)', 
    width: '100%', 
    height: '50px', 
    border: '1px solid transparent',
    paddingRight: '5%'
  },
  searchBarLeft: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'red',
    fontFamily: 'Roboto',
    fontSize: '20px'
  },
  searchBarRight: {
    height: '100%',
    width: '25%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  search: {
    backgroundColor: 'rgba(0,0,0, 0.4)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  filterButton: {
    backgroundColor: 'white',
    borderRadius: '10%',
    width: '30px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filter: {
    position: 'absolute',
    right: 0,
    top: 100,
    height: '40vh',
    width: '400px',
    backgroundColor: 'white',
    padding: '8px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 2,
    boxShadow: '-8px 10px 8px rgba(0,0,0,0.4)',
    zIndex: 10
  },
  collapseFilter: {
    display: 'none'
  },
  closeFilter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  filterSection: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  selectGroup: {
    padding: theme.spacing(2, 0)
  },
  stateSelect: {
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    position: 'absolute',
    right: 20,
    bottom: 20
  },
  error: {
    fontFamily: 'Roboto',
    color: 'rgb(230, 39, 39)' 
  }
}));

const Map = () => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searched, setSearched] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [ddnPanel, setDdnPanel] = useState("");
  const [epicAddBox, setEpicAddBox] = useState("");
  const [epicDelete, setEpicDelete] = useState("");
  const [deleting, setDeleting] = useState("");
  const [states, setStates] = useState(null);
  const [state, setState] = useState("");
  const [constituency, setConstituency] = useState("");
  const [constituencies, setConstituencies] = useState(null);
  const [error, setError] = useState(false);
  const [epicBuildingId, setEpicBuildingId] = useState("");
  const [epicState, setEpicState] = useState("");
  const [epicAssembly, setEpicAssembly] = useState("");
  const [epicParentId, setEpicParentId] = useState("");
  const [refreshList, setRefreshList] = useState(false);
  const [searchEpic, setSearchEpic] = useState("");
  const [epicNotFound, setEpicNotFound] = useState("");
  const [addingLocation, setAddingLocation] = useState(false);
  const [buildingCoordinates, setBuildingCoordinates] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v9',
      center: [80, 20],
      zoom: 4.5
    });
    let nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'bottom-left');
    map.on('move', async () => {
      setEpicNotFound('');
      const { lng, lat } = map.getCenter();
      setZoom(map.getZoom().toFixed(2));
      if(map.getZoom().toFixed(2) >= 18){
        let options = {
          'headers': {
            'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
          }
        };
        let response = await axios.get(`https://dev.zip.pr/eci/nearby?latitude=${lat}&longitude=${lng}`, options);
        if(response.data.ok){
          setBuildings(buildings => [
            ...buildings,
            ...response.data.response.buildings
          ]);
          for(let i = 0; i < response.data.response.buildings.length; i++){
            let mapSource = map.getSource(response.data.response.buildings[i].building_id);
            if(!mapSource){
              map.addSource(response.data.response.buildings[i].building_id, {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': response.data.response.buildings[i].centroid.coordinates
                  }
                }
              });
            }
            let mapLayer = map.getLayer(response.data.response.buildings[i].building_id);
            if(!mapLayer){
              map.addLayer({
                'id': response.data.response.buildings[i].building_id,
                'type': 'circle',
                "paint": {
                  "circle-radius": 6,
                  "circle-color": response.data.response.buildings[i].colour_code
                },
                "source": response.data.response.buildings[i].building_id
              });
            }
            let mapLabel = map.getLayer(`${response.data.response.buildings[i].building_id}-label`);
            if(!mapLabel){
              map.addLayer({
                'id': `${response.data.response.buildings[i].building_id}-label`,
                'type': 'symbol',
                'source': response.data.response.buildings[i].building_id,
                'layout': {
                  'text-field': `${response.data.response.buildings[i].building_id}(${response.data.response.buildings[i].epics_count})`,
                  'text-size': 14,
                  'text-variable-anchor': ["left"],
                  'text-radial-offset': 0.3,
                  'text-justify': 'auto',
                },
                'paint': {
                  'text-color': '#fff308'
                }
              });
            }
            map.on('click', response.data.response.buildings[i].building_id, function(e){
              setDdnPanel(response.data.response.buildings[i].building_id)
            });
            map.on('mousemove', response.data.response.buildings[i].building_id, function(e){
              map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', response.data.response.buildings[i].building_id, function(e){
              map.getCanvas().style.cursor = '';
            });
          }
        }
      }
    });
    setMap(map);
  },[])

  useEffect(() => {
    if(map){
      if(map.getZoom().toFixed(2) < 18){
        for(let i = 0; i < buildings.length; i++){
          let mapLayer = map.getLayer(buildings[i].building_id);
          if(mapLayer){
            map.removeLayer(buildings[i].building_id);
          }
          let mapLabel = map.getLayer(`${buildings[i].building_id}-label`);
          if(mapLabel){
            map.removeLayer(`${buildings[i].building_id}-label`)
          }
          let mapSource = map.getSource(buildings[i].building_id);
          if(mapSource){
            map.removeSource(buildings[i].building_id);
          }
        }
      }
    }
  },[buildings.length, zoom])

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let states = assigned_constituencies.map(item => item.state_display_name);
    setStates(states);
  },[])

  useEffect(() => {
    let assigned_constituencies = JSON.parse(localStorage.getItem('assigned_constituencies'));
    let state_constituencies = assigned_constituencies.filter(item => item.state_display_name === state);
    let constituencies = state_constituencies.map(item => item.ac_display_name);
    setConstituencies(constituencies);
    // setConstituency('');
    setConstituency(constituencies[0]);
  },[state])

  const setLocation = async (state, constituency) => {
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let result = await axios.get(`/v1/map/constituency?state=${stateParam}&constituency=${constituencyParam}&start_date=2020-02-26&end_date=2020-03-30`);
    if(!result.data.ok){
      setError(true);
      return
    }
    setFilterOpen(false);
    map.flyTo({center: result.data.response.center, zoom: 11.7})
    let mapSource = map.getSource(result.data.response.constituency);
    if(mapSource){
      window.location.reload()
    }
    map.addSource(result.data.response.constituency, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': result.data.response.coordinates
        }
      }
    });
    map.addLayer({
      'id': result.data.response.constituency,
      'type': 'fill',
      'source': result.data.response.constituency,
      'layout': {},
      'paint': {
      'fill-color': '#fff',
      'fill-opacity': 0.3,
      'fill-outline-color': 'yellow'
      }
    });
    map.addLayer({
      'id': 'labels',
      'type': 'symbol',
      'source': result.data.response.constituency,
      'layout': {
        'text-field': result.data.response.constituency
      },
      'paint': {
        'text-color': '#fff308'
      }
    })
    map.addLayer({
      'id': 'first-layer-borders',
      'type': 'line',
      'source': result.data.response.constituency,
      'layout': {},
      'paint': {
        'line-color': '#fff308',
        'line-width': 3
      }
    });
    map.on('mouseenter', result.data.response.constituency, function() {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', result.data.response.constituency, function() {
      map.getCanvas().style.cursor = '';
    });
    map.on('click',result.data.response.constituency, async function(e){
      handleConstituencyClick(result);
    });
    setMap(map);
  }

  const handleConstituencyClick = async (result) => {
    map.flyTo({center: result.data.response.center, zoom: 12});
    map.removeLayer('first-layer-borders');
    map.removeLayer(result.data.response.constituency);
    map.removeLayer('labels');
    map.addSource(`${result.data.response.constituency}-border`, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': result.data.response.coordinates
        }
      }
    });
    map.addLayer({
      'id': `${result.data.response.constituency}-border`,
      'type': 'fill',
      'source': `${result.data.response.constituency}-border`,
      'layout': {},
      'paint': {
      'fill-color': '#000',
      'fill-opacity': 0.3,
      'fill-outline-color': 'yellow'
      }
    });
    let state = localStorage.getItem('state');
    let constituency = localStorage.getItem('constituency');
    let constituencyParam = JSON.parse(localStorage.getItem('ac_map'))[constituency];
    let stateParam = JSON.parse(localStorage.getItem('state_map'))[state];
    let partResult = await axios.get(`/v1/map/constituency/parts?state=${stateParam}&constituency=${constituencyParam}&start_date=2020-02-26&end_date=2020-03-30`);
    for(let i = 0; i < partResult.data.response.length; i++){
      map.addSource(partResult.data.response[i].name, {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [ partResult.data.response[i].coordinates ]
          }
        }
      });
      map.addLayer({
        'id': partResult.data.response[i].name,
        'type': 'fill',
        'source': partResult.data.response[i].name,
        'paint': {
          'fill-color': '#000',
          'fill-opacity': 0.3
        }
      });
      map.addLayer({
        'id': `${partResult.data.response[i].name}-labels`,
        'type': 'symbol',
        'source': partResult.data.response[i].name,
        'layout': {
          'text-field': partResult.data.response[i].name
        },
        'paint': {
          'text-color': '#fff308'
        }
      });
      map.addLayer({
        'id': `${partResult.data.response[i].name}-borders`,
        'type': 'line',
        'source': partResult.data.response[i].name,
        'layout': {},
        'paint': {
          'line-color': '#fff308',
          'line-width': 3
        }
      });
      map.on('mousemove', partResult.data.response[i].name, function(e){
        map.getCanvas().style.cursor = 'pointer';
        let mapLayer = map.getLayer(`${partResult.data.response[i].name}-borders-onHover`);
        if(typeof mapLayer === 'undefined'){
          map.addLayer({
            'id': `${partResult.data.response[i].name}-borders-onHover`,
            'type': 'line',
            'source': partResult.data.response[i].name,
            'layout': {},
            'paint': {
              'line-color': '#fff308',
              'line-width': 3
            }
          });
        }
      });
      map.on('mouseleave', partResult.data.response[i].name, function(e){
        map.getCanvas().style.cursor = '';
        let mapLayer = map.getLayer(`${partResult.data.response[i].name}-borders-onHover`);
        if(typeof mapLayer !== 'undefined'){
          map.removeLayer(`${partResult.data.response[i].name}-borders-onHover`)
        }
      });
      map.on('click', partResult.data.response[i].name, async function(e){
        // setPart(partResult.data.response[i].name);
        handlePartClick(partResult, partResult.data.response[i])
      })
    }
    setMap(map);
  }

  const handlePartClick = async (partResult, part) => {
    map.flyTo({center: part.center, zoom: 18});
    for(let j = 0; j < partResult.data.response.length; j++){
      map.removeLayer(`${partResult.data.response[j].name}`);
      map.removeLayer(`${partResult.data.response[j].name}-labels`);
      map.removeLayer(`${partResult.data.response[j].name}-borders`);
    }
    let mapLayer = map.getLayer(`${part.name}-borders-onHover`);
    if(typeof mapLayer !== 'undefined'){
      map.removeLayer(`${part.name}-borders-onHover`)
    }
    let mapSource = map.getSource(part.name);
    if(typeof mapSource !== 'undefined'){
      map.removeSource(part.name)
    }
    map.addSource(part.name, {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [ part.coordinates ]
        }
      }
    });
    map.addLayer({
      'id': `${part.name}-borders`,
      'type': 'line',
      'source': part.name,
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 3
      }
    });
  }

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  }

  const handleFilterClose = () => {
    setFilterOpen(false);
  }

  useEffect(() => {
    if(map && !searched){
      let state = localStorage.getItem('state');
      setState(state);
      let constituency = localStorage.getItem('constituency');
      setConstituency(constituency);
      if(state && constituency){
        setTimeout(()=>{
          setLocation(state, constituency);
        },500)
      }
      setSearched(true);
    }
  },[map])

  const searchFormSubmit = async (e) => {
    e.preventDefault();
    let options = {
      'headers': {
        'x-zippr-api-key': '401c6d83-f89d-4a90-a387-c94c229abb2c'
      }
    };
    let response = await axios.get(`https://dev.zip.pr/eci/surveyed-epic?epic_no=${searchEpic}`, options);
    if(!response.data.ok){
      setEpicNotFound(`Epic ${searchEpic} not tagged to any building`)
      setSearchEpic('');
      return
    }
    setFilterOpen(false);
    map.flyTo({center: [response.data.response.epicDetails.longitude,response.data.response.epicDetails.latitude], zoom: 20});
    setSearchEpic('');
    setDdnPanel(response.data.response.epicDetails.building_id)
  }

  if(refreshList){
    map.zoomTo(17.9);
    setTimeout(() => {
      map.zoomTo(18);
    }, 1000)
  }

  if(addingLocation){
    let constituency_name = localStorage.getItem('constituency');
    let constituency = JSON.parse(localStorage.getItem('ac_map'))[constituency_name];
    let mapLayer = map.getLayer(`${constituency}-border-adding`);
    if(!mapLayer){
      map.addLayer({
        'id': `${constituency}-border-adding`,
        'type': 'fill',
        'source': `${constituency}-border`,
        'layout': {},
        'paint': {
        'fill-color': '#000',
        'fill-opacity': 0.3,
        'fill-outline-color': 'yellow'
        }
      });
    }
    map.on('mouseenter', `${constituency}-border-adding`, function() {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', `${constituency}-border-adding`, function() {
      map.getCanvas().style.cursor = '';
    });
    map.on('click', `${constituency}-border-adding`, async function(e){
      map.loadImage(
        'https://img.icons8.com/ultraviolet/64/marker.png',
        function(error, image) {
          if (error) throw error;
          if(map.hasImage('marker')){
            map.removeImage('marker')
          }
          map.addImage('marker', image);
          let mapSource = map.getSource('point');
          if(mapSource){
            map.removeLayer('point')
            map.removeSource('point')
          }
          map.addSource('point', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                  'type': 'Point',
                  'coordinates': [e.lngLat.lng, e.lngLat.lat]
                  }
                }
              ]
            }
          });
          map.addLayer({
            'id': 'point',
            'type': 'symbol',
            'source': 'point',
            'layout': {
              'icon-image': 'marker',
              'icon-size': 0.5,
              'icon-anchor': 'bottom'
            }
          });
          map.flyTo({center: [e.lngLat.lng, e.lngLat.lat], zoom: 21})
        }
      )
      setTimeout(() => {
        setBuildingCoordinates([e.lngLat.lng, e.lngLat.lat])
      },1000)
    });
  }

  const addBuildingHandler = (value) => {
    setBuildingCoordinates(value);
    let mapLayer = map.getLayer('point');
    if(mapLayer){
      map.removeLayer('point');
    }
    let mapSource = map.getSource('point');
    if(mapSource){
      map.removeSource('point');
    }
    let constituency_name = localStorage.getItem('constituency');
    let constituency = JSON.parse(localStorage.getItem('ac_map'))[constituency_name];
    map.removeLayer(`${constituency}-border-adding`)
    setAddingLocation(false);
    map.zoomTo(17.9);
    setTimeout(() => {
      map.zoomTo(18);
    }, 1000)
  }

  return (
    <div style={{position: 'relative'}}>
      <div ref={el => mapContainer.current = el} style={{height: '93.1vh', width: '100%', position: 'absolute', top: -10}} />
      <div className={classes.searchBar}>
        <div className={classes.searchBarLeft}>
          {epicNotFound}
        </div>
        <div className={classes.searchBarRight}>
          <form onSubmit={searchFormSubmit}>
            <div
              className={classes.search}
            >
              <SearchIcon className={classes.searchIcon}/>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search"
                value={searchEpic}
                onChange={(e) => {
                  setEpicNotFound('');
                  setSearchEpic(e.target.value)}
                }
              />
            </div>
          </form>
          <div 
            className={classes.filterButton}
            onClick={handleFilter}
          >
            <FilterListRoundedIcon />
          </div>
        </div>
      </div>
      <DDNPanel ddnPanel={ddnPanel} handle={setDdnPanel} epicAdd={setEpicAddBox} epicDelete={setEpicDelete} deleting={setDeleting} epicBuildingId={setEpicBuildingId} epicState={setEpicState} epicAssembly={setEpicAssembly} epicParentId={setEpicParentId} refreshList={refreshList} refreshHandle={setRefreshList}/>
      <Backdrop show1={epicAddBox}/>
      <EpicAddDetails show={epicAddBox} handle={setEpicAddBox} building={epicBuildingId} state={epicState} assembly={epicAssembly} parentId={epicParentId} refreshHandle={setRefreshList}/>
      <EpicDelete epicId={epicDelete} parentId={epicParentId} buildingId={epicBuildingId} deleting={deleting} handle={setEpicDelete} refreshHandle={setRefreshList}/>
      {zoom && zoom >= 18 ? <AddBuildingButton addHandle={setAddingLocation}/> : null}
      <AddBuildingModal handle={addBuildingHandler} showPanel={setDdnPanel} building={buildingCoordinates} constituency={localStorage.getItem('constituency')} state={state} />
      <div 
        className={filterOpen ? classes.filter : classes.collapseFilter}
      >
        <div 
          className={classes.closeFilter}
          onClick={handleFilterClose}
        >
          <CloseIcon/>
          <Typography>
            Close
          </Typography>
        </div>
        <ListSubheader
          style={{paddingLeft: '5px'}}
        >
          FILTERS
        </ListSubheader>
        <div className={classes.filterSection}>
          <div>
            <div className={classes.selectGroup}>
              <TextField
                className={classes.stateSelect}
                fullWidth
                margin="dense"
                name="State"
                select
                SelectProps={{ native: true }}
                variant="outlined"
                onChange={(e) => {
                  setError(false);
                  setState(e.target.value);
                  localStorage.setItem('state', e.target.value)
                }}
                value={state}
              >
                <option
                  disabled
                  value=""
                />
                {states && states.map((option) => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
            {constituencies ? <div className={classes.selectGroup}>
              <TextField
                className={classes.stateSelect}
                fullWidth
                margin="dense"
                name="Constituency"
                select
                SelectProps={{ native: true }}
                variant="outlined"
                onChange={(e) => {
                  setError(false);
                  setConstituency(e.target.value);
                  localStorage.setItem('constituency', e.target.value)
                }}
                value={constituency}
                // defaultValue="Select Constituency"
              >
                <option
                  disabled
                  value=""
                />
                {constituencies.map((option) => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </div> : null}
          </div>
          { error ? <div className={classes.error}>
            Constituency Not Found!
          </div> : null}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => setLocation(state, constituency)}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Map;
